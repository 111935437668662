import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Spin, Radio, Form, Input, InputNumber, message, Button, notification, Checkbox, Select } from 'antd';
import { account, authenticator, plan, vps } from '../../endpoint';
import { LockOutlined } from '@ant-design/icons';
import AppContext from './../../_until/AppContext';

const BuyVpsComponent = () => {
    const history  = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [dataPackage, setDataPackage] = useState([]);
    const [currentOs, setCurrentOs] = useState({});
    const [dataOs, setDataOs] = useState([]);
    const [dataRangeIps, setDataRangeIps] = useState(null);
    const [dataIsp, setDataIsp] = useState(null);
    const [dataState, setDataState] = useState(null);
    const [month, setMonth] = useState(1);
    const [qty, setQty] = useState(1);
    const [discount, setDiscount] = useState(0);
    const [checkedRadomPassword, setCheckedRadomPassword] = useState(true);
    const [checkedAutoRenew, setCheckedAutoRenew] = useState(true);
    const [checkIhaveRead, setCheckIhaveRead] = useState(false);
    const [listDiscount, setListDiscount] = useState(null);
    const [isLoadNotification, setLoadNotification] = useContext(AppContext);
    const { id } = useParams();
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    if (id == null) {
        history.push({pathname: '/home'});
    }

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    /**
     * Load list package vps
     */
    const loadPackage = () => {
        plan.getItemPackageVps(id).then(res => {
            if (res.status == 'success') {
                setDataPackage(res.response.package);
                setDataRangeIps(res.response.rangeIps);
                setDataIsp(res.response.isp);
                setDataState(res.response.state);
                document.title = res.response.package.plan_name + " - " + window?.title;
            }
            setLoading(false);
        }).then(res => {
        });
    }

    /**
     * Handle get discount
     *
     * @param month
     */
    const getDiscount = (month) => {
        const hide = message.loading('Processing...');
        vps.getDiscount({"month": month}).then(res => {
            if (res.status == 'success') {
                setDiscount(res.discount);
                setListDiscount(res.list_discount);
            }
            hide();
        }).then(res => {
            hide();
        });
    }

    /**
     * Load list Operating system
     */
    const loadListOs = () => {
        vps.getListOsFrontend().then(res => {
            if (res.status == 'success') {
                if (res.list_os) {
                    setCurrentOs(res.list_os[0]);
                }
                setDataOs(res.list_os);
            }
        }).then(res => {
        });
    }

    /**
     * Handle change month
     *
     * @param e
     */
    const onChangeMonth = (e) => {
        setMonth(e.target.value);
        getDiscount(e.target.value);
    };

    /**
     * Handle change randomd password
     *
     * @param e
     */
    const onChangeRandomPassword = (e) => {
        setCheckedRadomPassword(e.target.checked);
    };

    /**
     * Handle change auto renew
     *
     * @param e
     */
    const onChangeAutoRenew = (e) => {
        setCheckedAutoRenew(e.target.checked);
    };

    /**
     * Handle buy Vps
     *
     * @param fields
     */
    const onFinish = async (fields) => {
        if (!checkIhaveRead) {
            notification.open({
                message: 'Fail!',
                description: 'I have read and agree is required',
                type: 'error'
            });
            return;
        }
        const hide = message.loading('Processing...');
        setLoadingButton(true);
        try {
            var res = await vps.buyVps({...fields, 'auto_renew': checkedAutoRenew ? 1 : 0, 'id': id});
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                    type: 'success'
                });
                setLoadingButton(false);
                setLoadNotification(true);
                history.push({pathname: '/list-vps.html'});
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Buy failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    }

    /**
     * Handle calculater discount
     */
    const handledCalculaterDiscount = () => {
        return (dataPackage?.plan_price * discount)/100;
    }

    /**
     * Check os by ram and cpu
     *
     * @param ram
     * @param cpu
     */
    const checkOsByRamCpu = (ram, cpu, os) => {
        if (typeof ram == 'undefined' || typeof cpu == 'undefined') {
            return false;
        }
        ram = parseInt(ram.replace("GB", "").trim());
        cpu = parseInt(cpu.replace("Core", "").trim());
        if (ram < 2 || cpu < 2) {
            if (os == 'windows-server-2022-standard') {
                return false;
            }
            if (os == 'windows-10-enterprise') {
                return false;
            }
        }
        if (ram < 4) {
            if (os == 'windows-10-pro') {
                return false;
            }
            if (os == 'win-11-pro') {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Buy Cloud VPS - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadPackage();
                    loadListOs();
                }
            }).then(res => {
            });
            getDiscount(1);
        }
    }, []);
    
    return (
        <div className="container">
            {
                loading ? 
                (<div className="text-center mt-5"><Spin /></div>) : ''
            }
            <div className={(loading ? 'd-none' : '')}>
                <Form
                    name="basic"
                    onFinish={onFinish}
                >
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0  text-white">Validity Period </h4>
                                </div>
                                <div className='panel-body'>
                                    <div className="row-eq-height">
                                        <Form.Item
                                            label="Validity Period:"
                                            labelCol={{span: 24}}
                                            name="month"
                                            rules={[{ required: true, message: 'Please choose Validity Period !'}]}
                                            initialValue={1}
                                        >
                                            <Radio.Group onChange={onChangeMonth} value={month}>
                                                <Radio value={1}>
                                                    1 Month
                                                </Radio>
                                                <Radio value={3}>
                                                    3 Months<br/>
                                                    {
                                                        listDiscount?.discount_3 && (
                                                            <span className='radio_discount'>Buy 3 months get {listDiscount?.discount_3}% off</span>
                                                        )
                                                    }
                                                </Radio>
                                                <Radio value={6}>
                                                    6 Months <br/>
                                                    {
                                                        listDiscount?.discount_6 && (
                                                            <span className='radio_discount'>Buy 6 months get {listDiscount?.discount_6}% off</span>
                                                        )
                                                    }
                                                </Radio>
                                                <Radio value={12}>
                                                    12 Months<br/>
                                                    {
                                                        listDiscount?.discount_12 && (
                                                            <span className='radio_discount'>Buy 12 months get {listDiscount?.discount_12}% off</span>
                                                        )
                                                    }
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0  text-white">Quantity want to buy</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <Form.Item
                                                label="Quantity:"
                                                name="qty"
                                                labelCol={{span: 24}}
                                                rules={[{ required: true, message: 'Please enter Quantity!'}]}
                                                initialValue={qty}
                                            >
                                                <InputNumber min={1} max={50} placeholder="Quantity" style={{width: '100%'}} onChange={(value) => {setQty(value)}} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0 text-white">Operating system</h4>
                                </div>
                                <div className='panel-body'>
                                    <div className="row-eq-height group-choo-os">
                                        <Form.Item
                                            label="Operating system:"
                                            labelCol={{span: 24}}
                                            name="os"
                                            rules={[{ required: true, message: 'Please choose Operating system!'}]}
                                            initialValue='windows-server-2012r2-standard'
                                        >
                                            <Radio.Group>
                                                {
                                                    dataOs.length > 0 && dataOs.map((item, key) => 
                                                        checkOsByRamCpu(dataPackage?.ram, dataPackage?.cpu, item.key) && <Radio key={'os' + item.id} value={item.key} onChange={(e) => { setCurrentOs(item); }}>
                                                            <div className='os-item'>
                                                                <img src={window.base_url + item?.image} width="100px" />
                                                                <p>{item?.title}</p>
                                                            </div>
                                                        </Radio>
                                                    )
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0  text-white">Information</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            {
                                                dataState && dataState.length > 0 ? (
                                                    <Form.Item name="state" label="State:" labelCol={{span: 24}}>
                                                        <Select
                                                            style={{width: "100%"}}
                                                            placeholder="Random"
                                                            defaultValue="random"
                                                        >
                                                            <Option value="random">Random</Option>
                                                            {
                                                                dataState.map((item, key) => 
                                                                    <Option key={item} value={item}>{item}</Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                ) : ''
                                            }
                                            {
                                                dataIsp && dataIsp.length > 0 ? (
                                                    <Form.Item name="id_isp" label="Provider:" labelCol={{span: 24}}>
                                                        <Select
                                                            style={{width: "100%"}}
                                                            placeholder="Random"
                                                            defaultValue="random"
                                                        >
                                                            <Option value="random">Random</Option>
                                                            {
                                                                dataIsp.map((item, key) =>
                                                                    <Option key={item} value={item}>{item}</Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Form.Item name="random_password" labelCol={{span: 24}} style={{marginBottom: "5px"}}>
                                                <Checkbox checked={checkedRadomPassword} onChange={onChangeRandomPassword}>Random password</Checkbox>
                                            </Form.Item>
                                            {
                                                !checkedRadomPassword ? (
                                                    <div className="password-wrap mt-2">
                                                        <Form.Item
                                                            label="Password:"
                                                            labelCol={{span: 24}}
                                                            name="password"
                                                            rules={[
                                                                { required: true, message: 'Please input your password!' },
                                                                { minlength: 6, message: 'Password must have a minimum length of 6' },
                                                                { minlength: 30, message: 'Password must have a max length of 30' },
                                                                {
                                                                    pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                                                                    message: 'Password must contain at least 10 characters, 1 uppercase letter, 1 lowercase character, 1 special character and 1 number.'
                                                                }
                                                            ]}
                                                            style={{marginBottom: "5px"}}
                                                        >
                                                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} minLength={6} maxLength={30} placeholder="Password" />
                                                        </Form.Item>
                                                    </div>
                                                ) : ""
                                            }
                                            <div className="m-t-2" id="tos-checkbox">
                                                <Form.Item name="auto_renew" style={{marginBottom: "5px"}}>
                                                    <Checkbox checked={checkedAutoRenew} onChange={onChangeAutoRenew}>Auto-renew</Checkbox>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="panel panel-default panel-custom mb-4">
                                <div className="panel-heading">
                                    <h4 className="section-title mb-0 text-white">Information order</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="summary-content">
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Country</span>
                                                <span className="item-value">{dataPackage?.country}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">CPU</span>
                                                <span className="item-value">{dataPackage?.cpu}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">RAM</span>
                                                <span className="item-value">{dataPackage?.ram}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Disk</span>
                                                <span className="item-value">{dataPackage?.disk}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">IPv4</span>
                                                <span className="item-value">{dataPackage?.ipv4}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Bandwidth</span>
                                                <span className="item-value">{dataPackage?.bandwidth}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Ethernet Port</span>
                                                <span className="item-value">{dataPackage?.ethernet_port}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Type IP</span>
                                                <span className="item-value">{dataPackage?.type_ip}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Operating system</span>
                                                <span className="item-value">{currentOs?.title}</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item d-flex justify-content-between align-items-center">
                                                <span className="item-name">Price 1 month</span>
                                                <span className="item-value">{dataPackage?.plan_price} Point</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item list-item-main d-flex justify-content-between align-items-center">
                                                <span className="item-name">Validity Period</span>
                                                <span className="item-value">{month} months</span>
                                            </li>
                                        </ul>
                                        <ul className="summary-list">
                                            <li className="list-item list-item-main d-flex justify-content-between align-items-center">
                                                <span className="item-name">SubTotal</span>
                                                <span className="item-value">{dataPackage?.plan_price * month * qty} Point</span>
                                            </li>
                                        </ul>
                                        {
                                            discount > 0 && (
                                                <ul className="summary-list">
                                                    <li className="list-item d-flex justify-content-between align-items-center">
                                                        <span className="item-name">Discount ({discount}%)</span>
                                                        <span className="item-value">{parseInt(handledCalculaterDiscount() * month * qty)} Point</span>
                                                    </li>
                                                </ul>
                                            )
                                        }
                                        <hr/>
                                        <ul className="summary-list">
                                            <li className="list-item list-item-main d-flex justify-content-between align-items-center">
                                                <span className="item-name">Total</span>
                                                <span className="item-value">{dataPackage?.plan_price * month * qty - parseInt(handledCalculaterDiscount() * month * qty)} Point</span>
                                            </li>
                                            <li><span className='d-block text-bold'>Value $1=10 Points</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Form.Item
                                name="i_have_read"
                                valuePropName="checked"
                                rules={[
                                    { required: true, message: 'I have read and agree is required' }
                                ]}
                            >
                                <Checkbox checked={checkIhaveRead} onChange={(e) => {setCheckIhaveRead(e.target.checked);}}>I have read and agree to the <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target='_blank'>Terms of Service</a></Checkbox>
                            </Form.Item>
                            <Button type="primary" htmlType="submit" loading={loadingButton} className="medium d-block text-bold">
                                <i className="fa fa-share pr-1"></i> Payment Now
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}
export default BuyVpsComponent;
