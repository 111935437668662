import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import { CheckCircleOutlined } from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';
import ProDescriptions from '@ant-design/pro-descriptions';
import { user_tracking_confirm, authenticator } from '../../../../endpoint';
import { getParamsByUrl, constants } from '../../../../_until';

/**
 * Confirm
 *
 * @param record
 */
const handledConfirmOrder = async (record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await user_tracking_confirm.confirmOrder({'id': record.id});
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Confirm successfully.',
                type: 'success'
            });
            return true;
        } else {
            notification.open({
                message: 'Fail!',
                description: "Confirm Error!",
                type: 'error'
            });
        }
        return true;
    } catch (error) {
        console.log(error);
    }
    hide();
    return false;
};

const UserTrackingConfirm = () => {
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        const data = await user_tracking_confirm.getList(parameters);
        return data;
    }

    /**
     * Show payment method
     *
     * @param type
     */
    const renderPaymentMethod = (type) => {
        if (type.toLowerCase() == constants.PerfectMoney.toLowerCase()) {
            return 'Perfect Money'
        } else if (type.toLowerCase() == constants.WebMoney.toLowerCase()) {
            return 'WebMoney';
        } else if (type.toLowerCase() == constants.Bitcoin.toLowerCase()) {
            return 'Bitcoin (BTC)';
        } else if (type.toLowerCase() == constants.BitcoinCash.toLowerCase()) {
            return 'Bitcoin Cash (BCH)';
        } else if (type.toLowerCase() == constants.ERC20.toLowerCase()) {
            return 'USDT (ERC20)';
        } else if (type.toLowerCase() == constants.TRC20.toLowerCase()) {
            return 'USDT (TRC20)';
        } else if (type.toLowerCase() == constants.BSC.toLowerCase()) {//'bep20'
            return 'USDT BSC (BNB Smart Chain(BEP20))';
        } else if (type.toLowerCase() == constants.Credit.toLowerCase()) {
            return 'SS5 Point';
        } else if (type.toLowerCase() == constants.Litecoin.toLowerCase()) {
            return 'Litecoin';
        } else if (type.toLowerCase() == constants.DASH.toLowerCase()) {
            return 'DASH';
        } else if (type.toLowerCase() == constants.ETH.toLowerCase()) {
            return 'Ethereum (ETH)';
        } else if (type.toLowerCase() == constants.TRX.toLowerCase()) {
            return 'Tron (TRX)';
        } else {
            return 'Other';
        }
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "User Name",
            dataIndex: 'user_name',
            sorter: false,
            hideInSearch: true,
            initialValue: paramQuery?.user_name,
        },
        {
            title: "Plan Name",
            dataIndex: 'plan_name',
            sorter: false,
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.user_id}>{record.plan != null ? record?.plan : record?.package}</span>
            ]
        },
        {
            title: "Price",
            dataIndex: 'plan_price',
            sorter: false,
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.user_id}>${record.plan != null ? record?.plan_price : record?.package_price}</span>
            ]
        },
        {
            title:'Address',
            dataIndex: 'address',
            hideInSearch: true
        },
        {
            title:'Discount',
            dataIndex: 'discount',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'discount' + record.id}>{record.discount}%</span>
            ]
        },
        {
            title:'Type',
            dataIndex: 'type',
            valueEnum: {
                [constants.PerfectMoney.toLowerCase()]: {
                    text:'Perfect Money',
                },
                [constants.WebMoney.toLowerCase()]: {
                    text: "WebMoney"
                },
                [constants.Bitcoin.toLowerCase()]: {
                    text: "Bitcoin (BTC)"
                },
                [constants.BitcoinCash.toLowerCase()]: {
                    text: "Bitcoin Cash (BCH)"
                },
                [constants.USDT.toLowerCase()]: {
                    text: "USDT"
                },
                [constants.Credit.toLowerCase()]: {
                    text: "SS5 Point"
                },
                [constants.Litecoin.toLowerCase()]: {
                    text: "Litecoin"
                },
                [constants.DASH.toLowerCase()]: {
                    text: "DASH"
                },
                [constants.ETH.toLowerCase()]: {
                    text: "Ethereum (ETH)"
                },
                [constants.TRX.toLowerCase()]: {
                    text: "Tron (TRX)"
                }
            },
            render: (dom, item) => {
                return (
                    <span>{renderPaymentMethod(item.type)}</span>
                );
            }
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <Popconfirm
                    key={'confirm' + record.id}
                    className={record.is_confirm == 1 && 'd-none'}
                    title="Are you sure to confirm this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handledConfirmOrder(record);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a className="btn btn-success text-white"><CheckCircleOutlined /> Confirm</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="User Trial"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                ]}
                columns={columns}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
                rowClassName={(record, index) => (
                    (
                        record?.order_id) ? 'bg-green-tr' : ''
                    )
                }
            />

            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.sock}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default UserTrackingConfirm;
