import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Spin, Modal } from 'antd';
import { Link } from "react-router-dom";
import { Swiper } from "swiper";
import { plan, config, banner, account, partner, sock_list, vps } from '../../endpoint';

import s5ABC from '../../assets/images/abc.png';
import s5922 from '../../assets/images/922.png';
import s5PIA from '../../assets/images/pia.png';
import scenarioScraping from '../../assets/theme/images/scenario_scraping.png';
import scenarioInter from '../../assets/theme/images/scenario_inter.png';
import scenarioSocial from '../../assets/theme/images/scenario_social.png';
import scenarioBusiness from '../../assets/theme/images/scenario_business.png';
import scenarioSecurity from '../../assets/theme/images/scenario_security.png';
import scenarioAd from '../../assets/theme/images/scenario_ad.png';
import bannerHome from '../../assets/images/banner-home.png';

import PremiumList from './include/PremiumList';
import DailyList from './include/DailyList';
import Socks5List from './include/Socks5List';
import ProxyItem from './include/ProxyItem';
import VpsItem from './include/VpsItem';
import S5Item from './include/S5Item';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';

const { TabPane } = Tabs;
const Home = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(true);
    const [loadingPartner, setLoadingPartner] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [isModalVisiblePartner, setIsModalVisiblePartner] = useState(false);
    const [data, setData] = useState([]);
    const [partnerItem, setPartner] = useState({});
    const [listPartners, setListPartners] = useState([]);
    const [settingWebsite, setSettingWebsite] = useState({});
    const [notification, setNotification] = useState("");
    const [dataBanner, setBanner] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [totalSock, setTotalSock] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadingCountry, setLoadingCountry] = useState(true);
    const [datalistCountry, setDataCountry] = useState([]);
    const [profile, setProfile] = useState([]);
    const [dataListPackageProxy, setListPackageProxy] = useState([]);
    const [dataListPackageVps, setListPackageVps] = useState([]);
    const [isShowTrial, setIsShowTrial] = useState(false);
    const [isShowTrialProxy, setIsShowTrialProxy] = useState(false);
    const [isShowTrialVps, setIsShowTrialVps] = useState(false);
    const [dataOs, setDataOs] = useState([]);
    const swiper = useRef(null);
    const timeout = useRef(null);
    const stop = useRef(null);

    /**
     * initialize Swiper Partner
     */
    const initializeSwiper = () => {
        if (!swiper.current) {
            if ($('.swiper-slide').length > 0) {
                return new Swiper('.swiper', {
                    loop: true,
                    autoplay: {
                        delay: 0
                    },
                    speed: 12000,
                    slidesPerView: 5,
                    spaceBetween: 20,
                    loopAdditionalSlides: 5,
                    breakpoints: {
                        320: {
                            slidesPerView: 1,
                        },
                        480: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 2,
                        },
                        991: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 5
                        }
                    }
                });
            }
        }
    };

    /**
     * Start Marquee Swiper Partner
     */
    const startMarquee = () => {
        if (!stop.current) {
            if (swiper.current) {
                swiper.current.slideTo(swiper.current.slides.length, 12000);
                swiper.current.once("transitionEnd", function () {
                    swiper.current.slideTo(0, 0, false);
                    timeout.current = setTimeout(() => startMarquee(), 0);
                });
            } else {
                timeout.current = setTimeout(() => startMarquee(), 0);
            }
        }
    };

    /**
     * Handle nl2br textarea
     */
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    /**
     * Handle list partner
     */
    const loadListPartner = () => {
        setLoadingPartner(true);
        const parameters = {'category_id': 'all'};
        partner.getListFrontend(parameters).then(res => {
            if (res.status == 'success') {
                setListPartners(res.response);
                setTimeout(function() {
                    swiper.current = initializeSwiper();
                    const swiperWrapper = document.querySelectorAll('.swiper-wrapper');
                    for (const item of swiperWrapper) {
                        item.addEventListener('mouseover', function () {
                            stop.current = true;
                            clearTimeout(timeout.current);
                        });
                    }
                }, 2000);
                startMarquee();
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoadingPartner(false);
        }).then(complete => {
            setLoadingPartner(false);
        });
    }

    /**
     * Load list Operating system
     */
    const loadListOs = () => {
        vps.getListOsFrontend().then(res => {
            if (res.status == 'success') {
                setDataOs(res.list_os);
            }
        }).then(res => {
        });
    }

    useEffect(() => {
        document.title = "Home - " + window?.title;

        plan.getListPlan().then(res => {
            if (res.status == 'success') {
                setData(res.response);
                setUserInfo(res.info);
                setLoading(false);
            }
        });

        plan.getListPackageProxy().then(res => {
            if (res.status == 'success') {
                setListPackageProxy(res.response.proxy);
            }
        });

        plan.getListPackageVps({country: 'us'}).then(res => {
            if (res.status == 'success') {
                setListPackageVps(res.response.vps);
            }
        });

        banner.get_list_banner({position: 'banner_top'}).then(res => {
            if (res.status == 'success') {
                setBanner(res.response);
            }
        });

        account.notification_home().then(res => {
            if (res.status == 'success' && res.response) {
                setNotification(nl2br(res.response.config_description));
                var lastclear = localStorage.getItem('last_time');
                var timeNow  = (new Date()).getTime();
                if (!lastclear || (timeNow - lastclear) > 1000 * 60 * 60 * 24 * 24) {
                    setIsModalVisible(true);
                    localStorage.setItem('last_time', timeNow);
                }
            }
        });

        if (user != null) {
            account.profile().then(res => {
                if (res.status == 'success') {
                    setProfile(res.response);
                }
            });

            account.check_data_trial(user?.user_id).then(res => {
                if (res.status == 'success') {
                    if (res.is_sock == 1) {
                        setIsShowTrial(true);
                    }
                    if (res.is_proxy == 1) {
                        setIsShowTrialProxy(true);
                    }
                    if (res.is_vps == 1) {
                        setIsShowTrialVps(true);
                    }
                }
            });
        }

        config.getSettingWebsite().then(res => {
            if (res.status == 'success') {
                setSettingWebsite(res.response);
            }
        });

        loadListContry(1);
        loadListOs();
        loadListPartner();

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }
    }, []);

     /**
     * Handle cancel modal
     */
    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalVisibleAds(false);
        setIsModalVisiblePartner(false);
    };

    /**
     * Handle show modal ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    /**
     * Handle ok redirect
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle show modal partner
     *
     * @param record
     */
    const handleShowModalPartner = (record) => {
        setPartner(record);
        setIsModalVisiblePartner(true);
    };

    /**
     * Handle ok redirect partner
     */
    const handleOkPartner = () => {
        setIsModalVisiblePartner(false);
        window.open(partnerItem.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle load list country
     *
     * @param paged
     */
    const loadListContry = (paged) => {
        sock_list.getCountryAll({'page': paged, 'per_page': 20}).then(res => {
            if (res.status == 'success') {
                if (paged == 1) {
                    setDataCountry(res.response);
                } else {
                    setDataCountry(datalistCountry.concat(res.response));
                }
                setCurrent(paged + 1);
                setTotal(res.total);
                setTotalSock(res.totalSock);
                setDataCountry(datalistCountry.concat(res.response));
            }
        }).then(err => {
            setLoadingCountry(false);
            setLoadingMore(false);
        }).then(complete => {
            setLoadingCountry(false);
            setLoadingMore(false);
        });
    }

    /**
     * Handle load more list country
     */
    const loadListContryMore = () => {
        setLoadingMore(true);
        loadListContry(current);
    }

    return (
        <>
            <div className="home-page">
                <div className="main-banner banner-center banner-home">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 order-md-2">
                                <img src={bannerHome} className="img-fluid"/>
                            </div>
                            <div className="col-md-6 col-12 order-md-1">
                                <h1 className="text-left">Service Socks5 Cheap</h1>
                                <div className='banner-main-description'>
                                    <ul>
                                        <li><i className="fa fa-check-circle"></i> IP availability ≥99%</li>
                                        <li><i className="fa fa-check-circle"></i> Unlimited Bandwidth</li>
                                        <li><i className="fa fa-check-circle"></i> Supports Country, Region, city, zip, ISP, etc.</li>
                                        <li><i className="fa fa-check-circle"></i> Support fingerprint browser, simulator, mobile phone, etc.</li>
                                    </ul>
                                </div>
                                <div className="button-info d-flex flex-wrap">
                                    <a className="btn btn-primary">Free Trial</a>
                                    <a className="btn-continue-google" href={user != null ? '/' : "/signup-google"}>Signin with Google</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                        <div className={(loading ? " d-none" : "")}>
                            <div className="servicer pt-5 pb-5">
                                <div className="container">
                                    <h3 className='text-center mb-4'>Senior Partner</h3>
                                    <div className="row">
                                        {
                                            dataBanner && dataBanner.length > 0 && dataBanner.map((item, key) => 
                                                <div className={"col-12 col-sm-" + (item.is_full != 0 ? (12/item.is_full) : '12')} key={item.id}>
                                                    <a onClick={()=> handleShowModalAds(item)}>
                                                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                                                    </a>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white'>
                            <div className="product-box-list">
                                <div className="container">
                                    <div className="product-scenario-box">
                                        <div className="product-scenario-tit">Socks5 Usage Solutions</div>
                                        <div className="product-scenario-list row">
                                            <div className='col-12 col-md-4'>
                                                <a className="product-scenario-item">
                                                    <div className="scenario-item-img">
                                                        <img src={scenarioScraping} />
                                                    </div>
                                                    <div className="scenario-item-text">Web Scraping &amp; Crawling</div>
                                                </a>
                                            </div>
                                            <div className='col-12 col-md-4'>
                                                <a className="product-scenario-item">
                                                    <div className="scenario-item-img">
                                                        <img src={scenarioInter} />
                                                    </div>
                                                    <div className="scenario-item-text">Internet Security Protection</div>
                                                </a>
                                            </div>
                                            <div className='col-12 col-md-4'>
                                                <a className="product-scenario-item">
                                                    <div className="scenario-item-img">
                                                        <img src={scenarioSocial} />
                                                    </div>
                                                    <div className="scenario-item-text">Social Media Management</div>
                                                </a>
                                            </div>
                                            <div className='col-12 col-md-4'>
                                                <a className="product-scenario-item">
                                                    <div className="scenario-item-img">
                                                        <img src={scenarioBusiness} />
                                                    </div>
                                                    <div className="scenario-item-text">Business Service Optimization</div>
                                                </a>
                                            </div>
                                            <div className='col-12 col-md-4'>
                                                <a className="product-scenario-item">
                                                    <div className="scenario-item-img">
                                                        <img src={scenarioSecurity} />
                                                    </div>
                                                    <div className="scenario-item-text">Security and Firewall Evasion</div>
                                                </a>
                                            </div>
                                            <div className='col-12 col-md-4'>
                                                <a className="product-scenario-item">
                                                    <div className="scenario-item-img">
                                                        <img src={scenarioAd} />
                                                    </div>
                                                    <div className="scenario-item-text">Ad Verification &amp; Fraud Prevention</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='locations-list'>
                            <div className="container">
                                <div className={"text-center mt-5 mb-5" + (loadingCountry ? "" : " d-none")}><Spin /></div>
                                <div className={"locations-popular__container" + (loadingCountry ? " d-none" : "")}>
                                    <h2 className="locations-popular__heading">Discover Our Top Proxy Real Residential IPs Worldwide: {totalSock} IPs</h2>
                                    <p className='text-center map__desc'>Tailored to Meet Your Needs!</p>
                                    <div className="locations-countries__list">
                                        {
                                            datalistCountry.length > 0 && datalistCountry.map((sock, key) => {
                                                    return (
                                                        <div className="locations-countries__item" key={sock.value}>
                                                            <div className="locations-countries__image">
                                                                <img width="50" height="50" src={sock.country_img} />
                                                            </div>
                                                            <div className="locations-countries__content">
                                                                <span>{sock.name}</span>
                                                                <span>{sock.count} IPs</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                    {
                                        total > 0 && (current - 1) * 10 < total ? (
                                            <div className='text-center mt-5'>
                                                {
                                                    loadingMore  ? (<Spin />) : (
                                                        <a onClick={loadListContryMore} className='btn btn--rounded btn--allLocations map__all-locations'>
                                                            <div>
                                                                <span>Load More</span>
                                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.33358 5.53176L1.99343 0.191706C1.86992 0.068097 1.70504 0 1.52924 0C1.35343 0 1.18856 0.068097 1.06504 0.191706L0.671779 0.584873C0.415879 0.841066 0.415879 1.25745 0.671779 1.51326L5.15604 5.99751L0.666803 10.4867C0.543292 10.6104 0.475098 10.7751 0.475098 10.9508C0.475098 11.1267 0.543292 11.2915 0.666803 11.4152L1.06007 11.8083C1.18368 11.9319 1.34846 12 1.52426 12C1.70006 12 1.86494 11.9319 1.98845 11.8083L7.33358 6.46336C7.45738 6.33936 7.52538 6.1738 7.52499 5.99781C7.52538 5.82112 7.45738 5.65566 7.33358 5.53176Z" fill="#112446"></path></svg>
                                                            </div>
                                                        </a>)
                                                }
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='bg-white pt-3 pb-5'>
                            <div className='container tab-buy-socks'>
                                <Tabs defaultActiveKey='socks5_daiily' className='pb-4'>
                                    <TabPane tab="PLAN SOCKS5 DAILY" key="socks5_daiily">
                                        <div title="PLAN ISP SOCKS5" className="plan panel panel-default mb-4">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 DAILY</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_daily }}></div>
                                            </div>
                                        </div>
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <DailyList listData={data?.Daily} profile={profile} isShowTrial={isShowTrial} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="PLAN SOCKS5 LIST" key="socks5_list">
                                        <div title="PLAN ISP SOCKS5" className="plan panel panel-default mb-4">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 LIST</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_list }}></div>
                                            </div>
                                        </div>
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Socks5List listData={data?.List} profile={profile} isShowTrial={isShowTrial} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="PLAN ISP SOCKS5" key="socks5_premium">
                                        <div title="PLAN ISP SOCKS5" className="plan panel panel-default mb-4">
                                            <h3 className="panel-heading mb-2">PLAN ISP SOCKS5</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_premium }}></div>
                                            </div>
                                        </div>
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <PremiumList listData={data?.Premium} profile={profile} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                                <div className="plan-table mb-5 mt-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="plan panel panel-default">
                                                <h3 className="panel-heading mb-2">Static Datacenter Proxies</h3>
                                                <div className="panel-body">
                                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_package_proxy }}></div>
                                                </div>
                                            </div>
                                            <div className='list-package plan-table pl-0 pr-0'>
                                                <div className='row'>
                                                    {
                                                        dataListPackageProxy && dataListPackageProxy.length > 0 && dataListPackageProxy.map((item, key) => 
                                                            <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                <ProxyItem item={item} profile={profile} isShowTrialProxy={isShowTrialProxy} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="plan-table mb-5 mt-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="plan panel panel-default">
                                                <h3 className="panel-heading mb-2">Cloud VPS</h3>
                                                <div className="panel-body">
                                                    <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_package_vps }}></div>
                                                </div>
                                            </div>
                                            <Tabs className='tab-vps'>
                                                <TabPane tab="USA (US)" key="US">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'US' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Singapore (SG)" key="SG">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'SG' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Australia (AU)" key="AU">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'AU' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Canada (CA)" key="CA">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'CA' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="UK (UK)" key="UK">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'UK' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Germany (DE)" key="DE">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'DE' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="France (FR)" key="FR">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'FR' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Japanese (JP)" key="JP">
                                                    <div className='row'>
                                                        {
                                                            dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                item.country_code == 'JP' && (
                                                                    <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                        <VpsItem item={item} profile={profile} isShowTrialVps={isShowTrialVps} dataOs={dataOs} />
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                                <div className='plan-table mb-5 mt-5'>
                                    <div title="Reseller S5Proxy" className="plan panel panel-default mt-5">
                                        <h3 className="panel-heading mb-2">Reseller S5Proxy</h3>
                                    </div>
                                    <div className="plan-table mb-5 mt-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Tabs defaultActiveKey="isp-proxies">
                                                    <TabPane tab="ISP Proxies" key="isp-proxies">
                                                        <Tabs defaultActiveKey="922-s5proxy">
                                                            <TabPane tab="922 S5Proxy" key="922-s5proxy">
                                                                <div title="922 S5Proxy" className="plan panel panel-default mt-3">
                                                                    <h3 className="panel-heading mb-2">922 S5Proxy</h3>
                                                                    <div className="panel-body">
                                                                        <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_922 }}></div>
                                                                    </div>
                                                                </div>
                                                                <div className="plan-table mt-3">
                                                                    <div className="row">
                                                                        {
                                                                            data?.s5922 && data?.s5922.length > 0 && data?.s5922.map((item, key) => (
                                                                                <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                    <S5Item item={item} image={s5922} />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </TabPane>
                                                            {
                                                                window.isABCS5 && (
                                                                    <TabPane tab="ABC S5Proxy" key="abc-s5proxy">
                                                                        <div title="ABC S5Proxy" className="plan panel panel-default mt-3">
                                                                            <h3 className="panel-heading mb-2">ABC S5Proxy</h3>
                                                                            <div className="panel-body">
                                                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_abc }}></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="plan-table mt-3">
                                                                            <div className="row">
                                                                            {
                                                                                    data?.s5abc && data?.s5abc.length > 0 && data?.s5abc.map((item, key) => (
                                                                                        <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                            <S5Item item={item} image={s5ABC} />
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </TabPane>
                                                                )
                                                            }
                                                            <TabPane tab="PIA S5Proxy" key="pia-s5proxy">
                                                                <div title="PIA S5Proxy" className="plan panel panel-default mt-3">
                                                                    <h3 className="panel-heading mb-2">PIA S5Proxy</h3>
                                                                    <div className="panel-body">
                                                                        <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_pia }}></div>
                                                                    </div>
                                                                </div>
                                                                <div className="plan-table mt-3">
                                                                    <div className="row">
                                                                        {
                                                                            data?.s5pia && data?.s5pia.length > 0 && data?.s5pia.map((item, key) => (
                                                                                <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                    <S5Item item={item} image={s5PIA} />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </TabPane>
                                                        </Tabs>
                                                    </TabPane>
                                                    <TabPane tab="Traffic Plans" key="traffic-plans">
                                                        <Tabs defaultActiveKey="922-traffic-s5proxy">
                                                            <TabPane tab="922 S5Proxy" key="922-traffic-s5proxy">
                                                                <div title="922 S5Proxy" className="plan panel panel-default mt-3">
                                                                    <h3 className="panel-heading mb-2">922 S5Proxy</h3>
                                                                    <div className="panel-body">
                                                                        <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_traffic_922 }}></div>
                                                                    </div>
                                                                </div>
                                                                <div className="plan-table mt-3">
                                                                    <div className="row">
                                                                        {
                                                                            data?.s5922Traffic && data?.s5922Traffic.length > 0 && data?.s5922Traffic.map((item, key) => (
                                                                                <div className="col-lg-3 col-md-4 col-12" key={item.id}>
                                                                                    <S5Item item={item} image={s5922} />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </TabPane>
                                                        </Tabs>
                                                    </TabPane>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-white'>
                    <div className='page-partners'>
                        <div className="container">
                            {
                                loadingPartner ? (
                                    <div className="text-center"><Spin /></div>
                                ) : (
                                    <div className='row'>
                                        <div className="col-12 col-sm-12">
                                            <h4 className='text-center'>Partners</h4>
                                            <div className="swiper">
                                                <div className="swiper-wrapper">
                                                    {
                                                        listPartners && listPartners.length > 0 && listPartners.map((item, key) =>
                                                            key < 100 ? (
                                                                <div key={item.id} className="swiper-slide">
                                                                    <a onClick={() => { handleShowModalPartner(item) }} className='partner-item'>
                                                                        <img src={window.base_url + item.logo} />
                                                                        {item?.name}
                                                                    </a>
                                                                </div>
                                                            ) : ""
                                                        )
                                                    }
                                                    {
                                                        listPartners && listPartners.length == 0 && (
                                                            <div className="swiper-slide">&nbsp;</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <p className='text-center mt-4'>
                                                <Link to='/partners.html' className="text-bold">View All Partners</Link>
                                            </p>
                                        </div>
                                    </div>
                                )
                            }
                            <div className='fp_line'></div>
                        </div>
                    </div>
                </div>
                <div className="page-bottom home-payment-icon">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-6 col-md-auto  text-center">
                                <a href="https://payeer.com/" className='payment2 payment' target="_blank"></a>
                            </div>
                            <div className="col-6 col-md-auto text-center">
                                <a href="https://blockchain.info/" className='payment3 payment' target="_blank"></a>
                            </div>
                            <div className="col-6 col-md-auto text-center">
                                <a href="https://www.binance.com/" className='payment4 payment' target="_blank"></a>
                            </div>
                            <div className="col-6 col-md-auto text-center">
                                <a href="https://www.binance.com/" className='payment5 payment' target="_blank"></a>
                            </div>
                            <div className="col-6 col-md-auto text-center">
                                <a href="https://www.binance.com/" className='payment6 payment' target="_blank"></a>
                            </div>
                            <div className="col-6 col-md-auto text-center">
                                <a href="https://www.binance.com/" className='payment7 payment' target="_blank"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Notification" visible={isModalVisible} onCancel={handleCancel} footer={null} width={640}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: notification }}></p>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>

            <Modal visible={isModalVisiblePartner} onCancel={handleCancel} onOk={handleOkPartner} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line">
                            Dear customer,<br/><br/>
                            If you wish to access this website please note that we are not the owners of this website.<br/><br/>
                            Any purchase or sale between you and this website is not related to us<br/><br/>
                            By clicking 'OK' you will be redirected to this website.<br/><br/>
                            Please contact them before proceeding with the transaction.<br/><br/>
                            Thank you!
                        </p>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Home;
