import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Form, Input, Button, Select, DatePicker, message } from 'antd';
import { dashboard } from '../../../../endpoint';

const { RangePicker } = DatePicker;
const StatisticalComponent = () => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState({
        title: {
            display: true,
            text: 'Profit statistics'
        },
        hover: {
            animationDuration: 0
        },
        animation: {
            //duration: 1,
            onComplete: function () {
                var chartInstance = this.chart,
                ctx = chartInstance.ctx;
                var typeChart = 'month';

                //Chart.defaults.global.defaultFontSize 12
                if (typeChart == 'month') {
                    ctx.font = Chart.helpers.fontString(9.5, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                } else {
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                }
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                var datas = this.data.datasets;
                this.data.datasets.forEach(function (dataset, i) {
                    if (i == 1) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = datas[1].data[index]*24000 - datas[0].data[index];
                            ctx.fillText("Profit: " + Number(data).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "VNĐ", bar._model.x, bar._model.y - 2);
                        });
                    }
                });
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            style: {
                zIndex: 100
            },
            //backgroundColor: '#f00',
            callbacks: {
                label: function(tooltipItem, data) {
                    //console.log(tooltipItem);
                    var profit = data['datasets'][0]['data'][tooltipItem.index];
                    var rev = data['datasets'][1]['data'][tooltipItem.index];
                    var cost = data['datasets'][2]['data'][tooltipItem.index];
                    if (tooltipItem.datasetIndex == 0) {
                        return "Buy: " + Number(tooltipItem.yLabel).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "VNĐ";
                    } else if(tooltipItem.datasetIndex == 1) {
                        return "Sell: " + Number(tooltipItem.yLabel).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "$";
                    }
                    return Number(tooltipItem.yLabel) + "VNĐ";
                },
            },
        },
        responsive: true,
        scales: {
            xAxes: [{
                stacked: true,
                maxBarThickness: 80
            }],
            yAxes: [{
                stacked: true,
                display: true,
                ticks: {
                    //max: maxValue + 500,
                    suggestedMin: 0,
                    beginAtZero: true
                }
            }]
        },
        legend: {
            onClick: function(e, legendItem) {
            }
        }
    });
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Total Order',
                borderWidth: 1,
                backgroundColor: 'rgba(94,114,228,.1)',
                borderColor: 'rgb(94,114,228)',
                pointBorderColor: 'rgb(94,114,228)',
                pointBackgroundColor: 'rgb(94,114,228)'
            },
            {
                label: 'Total Price',
                borderWidth: 1,
                backgroundColor: 'rgba(79,195,247,.1)',
                borderColor: 'rgb(79,195,247)',
                pointBorderColor: 'rgb(79,195,247)',
                pointBackgroundColor: 'rgb(79,195,247)',
            },
        ]
    });

    /**
     * Get Data Statistical
     *
     * @param values
     */
    const getData = async (values) => {
        const data = await dashboard.getStatistical(values);
        if (data.status == 'success') {
            var response = data.response;
            //Bar chart
            let barData = {
                labels: response.label,
                datasets: [
                    {
                        label: 'Buy (' + response['total_cost'].toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +' VNĐ)',
                        backgroundColor: 'rgb(54, 162, 235)',
                        data: response['cost']
                    }, 
                    {
                        label: 'Sell (' + response['total_amount'].toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + '$ x 24000' +' = ' + (response['total_amount'] * 24000).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + 'VNĐ)',
                        backgroundColor: 'rgb(255, 99, 132)',
                        data: response['amout']
                    },
                    {
                        label: 'Profit (' + (response['total_amount'] * 24000 - response['total_cost']).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ' VNĐ)',
                        backgroundColor: 'rgb(75, 19, 100)',
                        data: []
                    }
                ]
            };
            setData(barData);
        }
    }
    
    /**
     * Handle filter data
     *
     * @param values
     */
    const onFinish = async (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);
        if (values.method == 's5_proxy') {
            values.s5 = 1;
        }
        await getData(values);
        setLoading(false);
        hide();
    };

    useEffect(() => {
        getData({'type': 'month'});
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Form name="basic" className="mb-4" onFinish={onFinish}>
                                <div className='row'>
                                    <div className='col-12 col-md-3'>
                                        <Form.Item
                                            label="Date:"
                                            labelCol={{span: 24}}
                                            name="datess"
                                        >
                                            <RangePicker style={{width: "100%"}} />
                                        </Form.Item>
                                    </div>
                                    <div className='col-12 col-md-3'>
                                        <Form.Item
                                            label="Type:"
                                            labelCol={{span: 24}}
                                            name="type"
                                        >
                                            <Select style={{width: "100%"}} placeholder="Type">
                                                <Option value="month">Month</Option>
                                                <Option value="day">7 day</Option>
                                                <Option value="year">Year</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='col-12 col-md-3'>
                                        <Form.Item
                                            label="Method:"
                                            labelCol={{span: 24}}
                                            name="method"
                                        >
                                            <Select style={{width: "100%"}} placeholder="Method">
                                                <Option value="vps_proxy">VPS/Proxy</Option>
                                                <Option value="s5_proxy">S5 Proxy</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='col-12 col-md-3'>
                                        <Button type="primary" htmlType="submit" loading={loading} className="medium mt-4">Submit</Button>
                                    </div>
                                </div>
                            </Form>
                            <Bar options={options} data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StatisticalComponent;
