import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Pagination, Form, Input, Button, Modal, Select, Spin, notification, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { account, authenticator } from '../../endpoint';
import { getParamsByUrlPaging, constants } from '../../_until';

const CDKeyList = () => {
    const history = useHistory();
    const [formNote] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [dataLists, setDataLists] = useState([]);
    const [first, setFirst] = useState(true);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [dataForm, setDataForm] = useState({});
    const [isModalVisibleNote, setModalVisibleNote] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [loadingButton, setLoadingButton] = useState(false);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    /**
     * Handle change page
     *
     * @param page
     * @param pageSize
     */
    const onChange = (page, pageSize) =>{
        setCurrent(page);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    /**
     * Load list order
     *
     * @param values
     */
    const loadList = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);

        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        account.get_list_cdkey(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setFirst(false);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    }

    /**
     * Get type
     *
     * @param type
     */
    const getType = (type) => {
        if (type == '922') {
            return '922 S5Proxy';
        } else if (type == 'abc') {
            return 'ABC S5Proxy';
        } else if (type == 'pia') {
            return 'PIA S5Proxy';
        } else if (type == '922Traffic') {
            return '922 Traffic S5Proxy';
        } else if (type == 'luna') {
            return 'Luna S5Proxy';
        }
        return '';
    }

    /**
     * Get text type qty
     *
     * @param type
     */
    const getTextTypeQty = (type) => {
        if (type == '922') {
            return 'IP';
        } else if (type == 'abc') {
            return 'IP';
        } else if (type == 'pia') {
            return 'IP';
        } else if (type == '922Traffic') {
            return 'GB';
        } else if (type == 'luna') {
            return 'GB';
        }
        return '';
    }

    /**
     * Handle set method
     *
     * @param method
     */
    const setType = (type) => {
        dataForm['type'] = type;
        loadList({current: current, pageSize: pageSize, ...dataForm});
    }

    /**
     * Handle Note
     *
     * @param record
     * @param values
     */
    const handledNote = async (record, values) => {
        const hide = message.loading('Processing...');
        try {
            setLoadingButton(true);
            var res = await account.noteCdkey(record.id, {...values});
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Note successfully',
                    type: 'success'
                });
                setModalVisibleNote(false);
                setLoadingButton(false);
                loadList({all: 1, ...paramQuery});
                return true;
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            console.log(error);
            notification.open({
                message: 'Fail!',
                description: 'Note failed, please try again!',
                type: 'error'
            });
        }
        setLoadingButton(false);
        return false;
    };

    /**
     * Handle close modal
     */
    const handleCancel = () => {
        setModalVisibleNote(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "CDKey Details - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadList({all: 1, ...paramQuery});
                    if(paramQuery?.current){
                        delete paramQuery['current'];
                        delete paramQuery['pageSize'];
                    }
                    setDataForm(paramQuery);
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            <div className="content">
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading">CDKey Details</div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <Form.Item name="Type">
                                            <Select style={{width: "100%"}} defaultValue={paramQuery?.type} placeholder="Type" onChange={setType}>
                                                <Option value="">All</Option>
                                                <Option value="922">922 S5Proxy</Option>
                                                <Option value="abc">ABC S5Proxy</Option>
                                                <Option value="pia">PIA S5Proxy</Option>
                                                <Option value="922Traffic">922 Traffic S5Proxy</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>CDK</th>
                                                <th>Type</th>
                                                <th>Exchange quantity (IP)</th>
                                                <th>Date</th>
                                                <th>Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                    <tr key={item.id}>
                                                        <td data-title="#">{((current - 1)*20) + key + 1}</td>
                                                        <td data-title="CDK">{item?.cdkey}</td>
                                                        <td data-title="Type">{getType(item?.type)}</td>
                                                        <td data-title="Exchange quantity (IP)">{item?.number_ip} {getTextTypeQty(item?.type)}</td>
                                                        <td data-title="Date">{item.date}</td>
                                                        <td data-title="Note">
                                                            {item?.note}
                                                            <a key={"edit-note" + item.id} className='pl-1'
                                                                onClick={() => {
                                                                    setCurrentRow(item);
                                                                    formNote.setFieldsValue(item);
                                                                    setModalVisibleNote(true);
                                                                }}
                                                            ><EditOutlined /></a>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                <td colSpan="6" className="text-center">
                                                    No data.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    total > 20 && !loading ? (
                                        <div className="paging mt-4">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize={pageSize} onChange={onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            <Modal visible={isModalVisibleNote} title="Note" footer={false} onCancel={handleCancel} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            name="basic"
                            form={formNote}
                            className="form"
                            onFinish={(values) => { handledNote(currentRow, values) }}
                        >
                            <Form.Item
                                label="Note:"
                                labelCol={{span: 24}}
                                name="note"
                                rules={[{ required: true, message: 'Please enter Note!' }]}
                            >
                                <Input.TextArea rows={4} defaultValue={currentRow?.note} />
                            </Form.Item>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button type="primary" htmlType="submit" loading={loadingButton} className="medium">Submit</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default CDKeyList;
