import React from 'react';
import { Modal, Form, Button } from 'antd';
import { ProFormSelect, ProFormText, ProFormDateTimePicker, ProFormDigit, StepsForm } from '@ant-design/pro-form';

const PlanUpdate = (props) => {

    return (
        <Modal
            width={420}
            bodyStyle={{
                padding: '32px 40px 48px',
            }}
            destroyOnClose
            footer={false}
            title="Edit Plan"
            visible={props.updateModalVisible}
            onCancel={() => {
                props.onCancel();
            }}
        >
            <Form
                onFinish={props.onSubmit}
                className='ant-form-vertical'
                initialValues={{
                    plan_name: props.values?.plan_name,
                    plan_daily_limit: props.values?.plan_daily_limit,
                    plan_price: props.values?.plan_price,
                    plan_expired: props.values?.plan_expired,
                    plan_type: {label: props.values?.plan_type, value: props.values?.plan_type},
                    total_receive: props.value?.total_receive
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    width="full"
                    name="plan_name"
                    label="Name:"
                />
                <ProFormSelect
                    width="full"
                    name="plan_type"
                    label="Plan Type"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => [
                        { label: 'Daily', value: 'Daily'},
                        { label: 'API', value: 'API' },
                        { label: 'Credit', value: 'Credit' },
                        { label: 'List', value: 'List' }
                    ]}
                    placeholder="Please select a type"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your type!' 
                        }
                    ]}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Daily Limit is required"
                        },
                    ]}
                    label="Daily Limit"
                    placeholder="Daily Limit"
                    name="plan_daily_limit"
                    width="100%"
                    min={0}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Price is required"
                        },
                    ]}
                    label="Price($)"
                    placeholder="Price($)"
                    name="plan_price"
                    width="100%"
                    min={0}
                    step="0.01"
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Expired(days) is required"
                        },
                    ]}
                    label="Expired(days)"
                    placeholder="Expired(days)"
                    name="plan_expired"
                    width="100%"
                    min={0}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Total Socks5 Receive is required"
                        },
                    ]}
                    placeholder="Total Socks5 Receive"
                    label="Total Socks5 Receive"
                    name="total_receive"
                    width="100%"
                    min={0}
                />
                <div className="text-right">
                    <Button type="primary" htmlType="submit">Update</Button>
                </div>
            </Form>
        </Modal>
    );
};
export default PlanUpdate;
