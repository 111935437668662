import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Badge, Spin, Modal } from 'antd';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import logo from '../../../assets/images/shopsocks5.png';
import { config, notification } from '../../../endpoint';
import AppContext from './../../../_until/AppContext';

import icPremium from '../../../assets/images/ic-premium.png';
import icList from '../../../assets/images/ic-list.png';
import icDaily from '../../../assets/images/ic-daily.png';
import icProxy from '../../../assets/images/ic-proxy.png';
import icWindow from '../../../assets/images/ic-window.png';
import icChrome from '../../../assets/images/ic-chrome.png';
import icFirefox from '../../../assets/images/ic-firefox.png';
import icEdge from '../../../assets/images/ic-edge.png';
import icApi from '../../../assets/images/ic-api.png';
import icDocument from '../../../assets/images/ic-document.png';
import ic24h from '../../../assets/images/ic-24h.png';
import icTrialMenu from '../../../assets/images/ic-free-trial-menu.png';
import icS5 from '../../../assets/images/ic-s5.png';
import flagAustralia from '../../../assets/images/flag/australia.png';
import flagCanada from '../../../assets/images/flag/canada.png';
import flagNetherlands from '../../../assets/images/flag/netherlands.png';
import flagSingapore from '../../../assets/images/flag/singapore.png';
import flagUnitedKingdom from '../../../assets/images/flag/united-kingdom.png';
import flagUnitedStates from '../../../assets/images/flag/united-states.png';
import flagGermany from '../../../assets/images/flag/germany.png';
import flagFrance from '../../../assets/images/flag/france.png';
import flagJapan from '../../../assets/images/flag/japan.png';

import icAdTech from '../../../assets/images/ic-ad-tech.png';
import icContact from '../../../assets/images/ic-contact.png';
import icDataScraping from '../../../assets/images/ic-data-scraping.png';
import icFaq from '../../../assets/images/ic-faq.png';
import icGuide from '../../../assets/images/ic-guide.png';
import icMarkerResearch from '../../../assets/images/ic-marker-research.png';
import icReviewMonitoring from '../../../assets/images/ic-review-monitoring.png';
import icSerpSeo from '../../../assets/images/ic-serp-seo.png';
import icSneakerTicket from '../../../assets/images/ic-sneaker-ticket.png';
import icSocialMedia from '../../../assets/images/ic-social-media.png';
import icTravelFare from '../../../assets/images/ic-travel-fare.png';
import bannerLogo from '../../../assets/theme/images/banner_logo.png';

const Header = () => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));
    const [settingMenu, setSettingMenu] = useState(null);
    const [loadFirst, setLoadFirst] = useState(false);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [type, setType] = useState('all');
    const [hasMore, setHasMore] = useState(true);
    const [hasUserView, setHasUserView] = useState(false);
    const [changeTabLoading, setChangeTabLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [settingWebsite, setSettingWebsite] = useState({});
    const [current, setCurrent] = useState([]);
    const [isLoadNotification, setLoadNotification] = useContext(AppContext);

    /**
     * Handle logout
     */
    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        history.push({pathname: '/login.html'});
    }

    /**
     * Handle format number
     *
     * @param num
     */
    const formatField = (num) => {
        var numx = (num < 10)? "0" + num : num;
        return numx;
    }

    /**
     * Handle show timenow
     */
    const timeNow = () => {
        var d = new Date((new Date).toLocaleString('en-US', {
            timeZone: 'Asia/Ho_Chi_Minh'
        }));
        var day = d.getDate();
        var month = d.getMonth();
        var year = d.getFullYear();
        var hour = d.getHours();
        var minutes = d.getMinutes();
        var seconds = d.getSeconds();
        var innerHTML = (hour) + ":" + (minutes) + ":" + formatField(seconds) + " (" + day + "/" + (formatField(month+1)) + "/" + year + ")";
        $("#timecontainer").html(innerHTML);
    }

    /**
     * Handle active menu
     *
     * @param routeName
     */
    const activeRoute = (routeName) => {
        const location = useLocation();
        return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }

    /**
     * Handle click show nav
     */
    const onClick = () => {
        $('#navbarColor03').toggleClass('show');
    }

    /**
     * Handle get notification list
     *
     * @param typeNotification
     * @param pageSize
     */
    const getNotification = (typeNotification = '', pageSize = 0) => {
        if (typeNotification == '') {
            typeNotification = type;
        }
        if (pageSize == 0) {
            pageSize = page;
        }
        notification.getListWebsite({type: typeNotification, current: pageSize, pageSize: 5}).then(res => {
            if (res.status == 'success') {
                if (res.current * 5 >= res.total) {
                    setHasMore(false);
                }
                if (pageSize == 1) {
                    setData(res.response);
                } else {
                    setData(data.concat(res.response));
                }
                setCount(res.not_ready);
                setTotal(res.total);
                setPage(res.current + 1);
                setHasUserView(res.view_is_all);
                setLoadNotification(false);
                if (!loadFirst) {
                    setLoadFirst(true);
                }
            }
        }).then(comp => {
            setChangeTabLoading(false);
        }).then(error => {
            setChangeTabLoading(false);
        });
    }

    /**
     * Handle change tab notification
     *
     * @param typeTab
     * @param isLoading
     */
    const changeTab = (typeTab, isLoading = false) => {
        if (typeTab != type || isLoading) {
            setChangeTabLoading(true);
            setType(typeTab);
            setHasMore(true);
            setData([]);
            setPage(1);
            getNotification(typeTab, 1);
        }
    }

    /**
     * Handle change all status notification
     */
    const ChangeAll = () => {
        notification.changeAllStatusWebsite({'status': 1}).then(res => {
            if (res.status == 'success') {
                $('.message-item').removeClass('not-ready');
                setCount(0);
            }
        }).then(comp => {
        }).then(error => {
        });
    }

    /**
     * Handle show modal detail notification
     *
     * @param item
     */
    const showModal = (item) => {
        setCurrent(item);
        setIsModalVisible(true);

        notification.changeStatusWebsite(item?.id, {'status': 1}).then(res => {
            if (res.status == 'success') {
                $('#message-item'+ item?.id).removeClass('not-ready');
                if(count - 1 >= 0){
                    setCount(count - 1);
                }
            }
        }).then(comp => {
        }).then(error => {
        });
    };

    /**
     * Handle close modal detail notification
     */
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    /**
     * Handle close modal detail notification
     */
    const handleLoadNotificationAfter1M = () => {
        setInterval(function() {
            changeTab('all', true);
        }, 60*1000);
    }

    useEffect(() => {
        setInterval(timeNow, 1000);
        const headerMenuItems = document.querySelectorAll('.header_menu_item');
        for (const item of headerMenuItems) {
            item.addEventListener('mouseover', function () {
                if (window.screen.width > 991) {
                    document.querySelector('.header').classList.add('active_scroll');
                    this.classList.add('is_show_sub');
                    for (const sibling of item.parentNode.children) {
                        if (sibling !== item) {
                            sibling.classList.remove('is_show_sub');
                        }
                    }
                }
            });
        }
        for (const item of headerMenuItems) {
            item.addEventListener('mouseout', function () {
                if (window.screen.width > 991) {
                    for (const sibling of item.parentNode.children) {
                        sibling.classList.remove('is_show_sub');
                    }
                }
            });
        }
        if (!settingMenu) {
            config.getSettingMenu().then(res => {
                if (res.status == 'success') {
                    if (res.response != null) {
                        setSettingMenu(res.response);
                    }
                }
            });
            config.getSettingWebsite().then(res => {
                if (res.status == 'success') {
                    setSettingWebsite(res.response);
                }
            });
            handleLoadNotificationAfter1M();
        }
        if (user != null) {
            if (!loadFirst) {
                getNotification();
            } else {
                changeTab('all', true);
            }
        }
    }, [isLoadNotification]);

    return (
        <>
            {
                settingWebsite?.is_ads == 1 && (
                    <div className="ads">
                        <div className="bf_banner">
                            <div className="cont_ads_banner traffic_banner">
                                <div className="ads_text_info">
                                    <img className="bf_logo" src={bannerLogo} />
                                    <div className="black_banner_text1_left">Limited Time Offer</div>
                                    <p className="black_banner_text1">
                                        <i className="bbt-line"></i>
                                        <strong className='text_ads1'>{settingWebsite?.text_ads1}</strong>
                                        <b>{settingWebsite?.text_ads2}</b>
                                        <em className="bbt_discount">{settingWebsite?.text_ads3}</em>
                                        <span>{settingWebsite?.text_ads4}</span>
                                        <a href={settingWebsite?.ads_url} className="black_banner_text2">Get the Deal</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <header className="header">
                <nav className="container p-0 navbar navbar-expand-lg navbar-light navbar-default">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} width="200px" />
                    </Link>
                    <div className="collapse navbar-collapse" id="navbarColor03">
                        <ul className="navbar-nav ml-auto menu-right menu-mobile">
                            <li className={activeRoute('/home')}>
                                <Link to="/home">Home</Link>
                            </li>
                            <li className={activeRoute('buy-socks5.html') + (settingMenu?.buy_socks5 == 1 ? ' new buysock5' : ' buysock5') + " header_menu_item"}>
                                <Link to="/buy-socks5.html">Pricing <i className="fa fa-angle-down"></i></Link>
                                <span className='arrow-mobile arrow-buy-socks5' onClick={() => { $('.arrow-buy-socks5').toggleClass('is-show'); $('.arrow-buy-socks5').parent().find('.header_submenu').slideToggle('slow'); }}><i className="fa fa-angle-right"></i></span>
                                <div className="header_submenu">
                                    <div className="header_submenu_conts">
                                        <div className="regular_proxy">
                                            <p className="proxy_name_title">Public Socks5</p>
                                            <div className="proxy_name_cont">
                                                <a href="/buy-socks5.html?tab=socks5_premium" className="meal_isp">
                                                    <div className="pricing_cont">
                                                        <p className="pricing_title">
                                                            <img src={icPremium} alt="ISP Socks5"/> <b>ISP Socks5</b>
                                                        </p>
                                                        <p className="pricing_intrudt">
                                                            ISP Socks5 are charged on a per-IP basis, and each IP can be used for a variable length of time, up to a maximum of 24 hours. For customers with low daily usage needs
                                                        </p>
                                                    </div>
                                                    <p className="pricing_unit">
                                                        <b>Start From</b><br/>
                                                        <span>$0.01</span>/IP
                                                    </p>
                                                </a>
                                                <a href="/buy-socks5.html?tab=socks5_daily" className="meal_traffic">
                                                    <div className="pricing_cont">
                                                        <p className="pricing_title">
                                                            <img src={icDaily} alt="Socks5 Daily"/>
                                                            <b>Socks5 Daily</b>
                                                        </p>
                                                        <p className="pricing_intrudt">Billed by bind times. Your account will be reset everyday. (Get 1 Socks5/1 time)<br/>For customers with high daily usage needs</p>
                                                    </div>
                                                    <p className="pricing_unit">
                                                        <b>Start From</b><br/>
                                                        <span>$0.24</span>/Day
                                                        <span className='d-block'><img src={icTrialMenu} width="80px" alt="Trial"/></span>
                                                    </p>
                                                </a>
                                                <a href="/buy-socks5.html?tab=socks5_list" className="meal_static">
                                                    <div className="pricing_cont">
                                                        <p className="pricing_title">
                                                            <img src={icList} alt="Socks5 List"/>
                                                            <b>Socks5 List</b>
                                                        </p>
                                                        <p className="pricing_intrudt">Billed by bind times. Your account will be reset everyday. (Get List Socks5/1 time) <br/>For customers with high daily usage needs</p>
                                                    </div>
                                                    <p className="pricing_unit">
                                                        <b>Start From</b><br/>
                                                        <span>$0.24</span>/Day
                                                        <span className='d-block'><img src={icTrialMenu} width="80px" alt="Trial"/></span>
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="regular_proxy enter_proxy">
                                            <p className="proxy_name_title">Private: User Pass Auth</p>
                                            <div className='d-md-flex'>
                                                <div className="proxy_name_cont">
                                                    <a href="/buy-socks5.html?tab=static_proxies" className="meal_traffic">
                                                        <div className="pricing_cont">
                                                            <p className="pricing_title">
                                                                <img src={icProxy} alt=""/>
                                                                <b>Static Datacenter Proxies</b>
                                                            </p>
                                                            <p className="pricing_intrudt">Billed by amount of purchased IPs and plan period, unlimited bandwidth, high Speed and stable.</p>
                                                        </div>
                                                        <p className="pricing_unit">
                                                            <b>Start From</b><br/>
                                                            <span>$3</span>/MONTH
                                                            <span className='d-block'><img src={icTrialMenu} width="80px" alt="Trial"/></span>
                                                        </p>
                                                    </a>
                                                    <a href="/buy-socks5.html?tab=cloud_vps" className="meal_traffic">
                                                        <div className="pricing_cont">
                                                            <p className="pricing_title">
                                                                <img src={icProxy} alt=""/>
                                                                <b>Cloud VPS</b>
                                                            </p>
                                                            <p className="pricing_intrudt">Simple and quick — your cloud environment at the best price</p>
                                                        </div>
                                                        <p className="pricing_unit">
                                                            <b>Start From</b><br/>
                                                            <span>$4.6</span>/MONTH
                                                            <span className='d-block'><img src={icTrialMenu} width="80px" alt="Trial"/></span>
                                                        </p>
                                                    </a>
                                                    <a href="/buy-socks5.html?tab=s5" className="meal_traffic">
                                                        <div className="pricing_cont">
                                                            <p className="pricing_title">
                                                                <img src={icS5} alt=""/>
                                                                <b>Reseller S5Proxy Residential</b>
                                                            </p>
                                                            <p className="pricing_intrudt">922proxy.com, piaproxy.com, abcproxy.com</p>
                                                        </div>
                                                        <p className="pricing_unit">
                                                            <b>Start From</b><br/>
                                                            <span>$0.05</span>/IP
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="regular_proxy enter_proxy">
                                            <p className="proxy_name_title">Locations</p>
                                            <div className='d-md-flex'>
                                                <div className="proxy_name_cont">
                                                    <div className='row'>
                                                        <div className='col-12 col-md-6'>
                                                            <p className="proxy_name_title"><b className='text-primary'>AREA</b></p>
                                                            <div className='proxy_name_cont proxy_name_downs' style={{border: 0, paddingTop: 0}}>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagSingapore} width="24px" height="24px" /> Singapore (SG)
                                                                </a>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagAustralia} width="24px" height="24px" /> Australia (AU)
                                                                </a>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagJapan} width="24px" height="24px" /> Japanese (JP)
                                                                </a>
                                                            </div>
                                                            <p className="proxy_name_title mt-3"><b className='text-primary'>AMERICAN AREA</b></p>
                                                            <div className='proxy_name_cont proxy_name_downs' style={{border: 0, paddingTop: 0}}>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagUnitedStates} width="24px" height="24px" /> United States (US)
                                                                </a>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagCanada} width="24px" height="24px" /> Canada (CA)
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-6'>
                                                            <p className="proxy_name_title"><b className='text-primary'>EUROPE AREA</b></p>
                                                            <div className='proxy_name_cont proxy_name_downs' style={{border: 0, paddingTop: 0}}>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagUnitedKingdom} width="24px" height="24px" /> United Kingdom (UK)
                                                                </a>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagGermany} width="24px" height="24px" /> Germany (DE)
                                                                </a>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black">
                                                                    <img src={flagFrance} width="24px" height="24px" /> France (FR)
                                                                </a>
                                                                <a href="/buy-socks5.html?tab=cloud_vps" className="user_axect_link text-black d-none">
                                                                    <img src={flagNetherlands} width="24px" height="24px" /> Netherlands (NL)
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={activeRoute('socks5-premium.html') + (settingMenu?.get_socks5 == 1 ? ' new' : '') + " header_menu_item"}>
                                <a>Get Socks5 Proxies <i className="fa fa-angle-down"></i></a>
                                <span className='arrow-mobile arrow-premium' onClick={() => { $('.arrow-premium').toggleClass('is-show'); $('.arrow-premium').parent().find('.header_submenu').slideToggle('slow'); }}><i className="fa fa-angle-right"></i></span>
                                <div className="header_submenu">
                                    <div className="header_submenu_conts">
                                        <div className="proxy_tools">
                                            <p className="proxy_name_title">Public Socks5</p>
                                            <div className="proxy_name_cont proxy-name-userauth">
                                                <a className="user_axect_link" href="/socks5-premium.html">
                                                    <div>
                                                        <p><img src={icPremium} width="24px" height="24px" /> <b>ISP Socks5</b></p>
                                                        <p>ISP Socks5 are charged on a per-IP basis, and each IP can be used for a variable length of time, up to a maximum of 24 hours.</p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link" href="/view-sock.html">
                                                    <div>
                                                        <p><img src={icDaily} width="24px" height="24px" /> <b>Socks5 Daily</b></p>
                                                        <p>Billed by bind times. Your account will be reset everyday. (Get 1 Socks5/1 time)</p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link" href="/socks5-list.html">
                                                    <div>
                                                        <p><img src={icList} width="24px" height="24px" /> <b>Socks5 List</b></p>
                                                        <p>Billed by bind times. Your account will be reset everyday. (Get List Socks5/1 time)</p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link" href="/socks5-24h.html">
                                                    <div>
                                                        <p><img src={ic24h} width="24px" height="24px" /> <b>Socks5 24h</b></p>
                                                        <p>List socks5 get today</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="proxy_tools">
                                            <p className="proxy_name_title">Private: User Pass Auth</p>
                                            <div className="proxy_name_cont proxy-name-whiteip">
                                                <a className="user_axect_link" href="/list-proxy.html">
                                                    <div>
                                                        <p>
                                                            <img src={icProxy} width="24px" height="24px" />
                                                            <b> Static Datacenter Proxies Manage</b>
                                                        </p>
                                                        <p>Billed by amount of purchased IPs and plan period, unlimited bandwidth, high Speed and stable.</p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link" href="/list-vps.html">
                                                    <div>
                                                        <p>
                                                            <img src={icProxy} width="24px" height="24px" />
                                                            <b> Cloud VPS Manage</b>
                                                        </p>
                                                        <p>Simple and quick — your cloud environment at the best price</p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link" href="/cdkey-detail.html">
                                                    <div>
                                                        <p>
                                                            <img src={icS5} width="24px" height="24px" />
                                                            <b> Cdkey Details S5Proxy</b>
                                                        </p>
                                                        <p>922proxy.com, piaproxy.com, abcproxy.com</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="proxy_tools">
                                            <p className="proxy_name_title">Socks5 Software</p>
                                            <div className="proxy_name_cont proxy_name_downs">
                                                <a href="/faq-detail/how-to-use-shopsocks5-socks5-client-windows" className='d-none'>
                                                    <img src={icWindow} />
                                                    <b>Socks5 Client Windows</b>
                                                </a>
                                                <a href="/faq-detail/how-to-use-shopsocks5-socks5-client-chrome-browser">
                                                    <img src={icChrome} />
                                                    <b>Socks5 Client Chrome browser</b>
                                                </a>
                                                <a href="/faq-detail/how-to-use-shopsocks5-socks5-client-firefox-browser">
                                                    <img src={icFirefox} />
                                                    <b>Socks5 Client Firefox browser</b>
                                                </a>
                                                <a href="/faq-detail/how-to-use-shopsocks5-socks5-client-Microsoft-Egde-browser">
                                                    <img src={icEdge} />
                                                    <b>Socks5 Client Microsoft Egde</b>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="proxy_tools">
                                            <p className="proxy_name_title">API Socks5</p>
                                            <div className="proxy_name_cont proxy_name_downs">
                                                <a href="/faq-detail/api">
                                                    <div>
                                                        <p><img src={icApi} width="24px" height="24px" /> <b>API</b></p>
                                                        <p>Allows access to the proxy service by making API interface requests</p>
                                                    </div>
                                                </a>
                                                <a href="/document">
                                                    <div>
                                                        <p><img src={icDocument} width="24px" height="24px" /> <b>Document</b></p>
                                                        <p>Specify parameters containing country, city, postal code, etc. Get API URL to add to target software or script.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={(settingMenu?.help_center == 1 ? ' new' : '') + " header_menu_item"}>
                                <a>Help Center <i className="fa fa-angle-down"></i></a>
                                <span className='arrow-mobile arrow-help-center' onClick={() => { $('.arrow-help-center').toggleClass('is-show'); $('.arrow-help-center').parent().find('.header_submenu').slideToggle('slow'); }}><i className="fa fa-angle-right"></i></span>
                                <div className="header_submenu">
                                    <div className="header_submenu_conts">
                                        <p className="proxy_name_title" style={{borderBottom: '1px solid #ecf2ff', width: '100%', paddingBottom: '30px'}}>GETTING STARTED</p>
                                    </div>
                                    <div className="header_submenu_conts custom-3column">
                                        <div className="proxy_tools ml-0">
                                            <div className="proxy_name_cont proxy-name-userauth b-0">
                                                <a className="user_axect_link" href="/faq-detail/faq">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'><img src={icFaq} width="48px" height="48px" style={{minWidth: "48px"}} /></p>
                                                        <p>
                                                            <b>FAQS</b><br/>
                                                            Learn everything about Shopsocks5.com products
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="proxy_tools">
                                            <div className="proxy_name_cont proxy-name-whiteip">
                                                <a className="user_axect_link" href="/faqs.html">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icGuide} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b>User Guide</b><br/>
                                                            All the information you need to get up and running
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="proxy_tools">
                                            <div className="proxy_name_cont proxy_name_downs">
                                                <a className="user_axect_link" href="/faq-detail/Contact_Us">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icContact} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b>Contact</b><br/>
                                                            Contact our support team
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={(settingMenu?.solutions == 1 ? ' new' : '') + " header_menu_item"}>
                                <a>Solutions <i className="fa fa-angle-down"></i></a>
                                <span className='arrow-mobile arrow-solutions' onClick={() => { $('.arrow-solutions').toggleClass('is-show'); $('.arrow-solutions').parent().find('.header_submenu').slideToggle('slow'); }}><i className="fa fa-angle-right"></i></span>
                                <div className="header_submenu">
                                    <div className="header_submenu_conts">
                                        <p className="proxy_name_title" style={{borderBottom: '1px solid #ecf2ff', width: '100%', paddingBottom: '30px'}}>USE CASE</p>
                                    </div>
                                    <div className="header_submenu_conts custom-2column">
                                        <div className="proxy_tools ml-0">
                                            <div className="proxy_name_cont proxy-name-whiteip">
                                                <a className="user_axect_link mb-4" href="/faq-detail/marketressarch">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icMarkerResearch} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b>Market Ressarch</b><br/>
                                                            Gather information from each niche.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link mb-4" href="/faq-detail/travelaggregation">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icTravelFare} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b>Travel Fare Aggregation</b><br/>
                                                            Compare prices and achieve thecompetitive advantage straight away.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link mb-4" href="/faq-detail/SERP-SEO">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icSerpSeo} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b>SERP & SEO</b><br/>
                                                            Optimize digital presence and caim yourplace on the ladder of success.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link mb-4" href="/faq-detail/AdTech">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icAdTech} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b>Ad Tech</b><br/>
                                                            Protect your brand, verify ads. & conductreal- time ad intelligence for optimized data-driven campaigns.
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="proxy_tools">
                                            <div className="proxy_name_cont proxy_name_downs">
                                                <a className="user_axect_link mb-4" href="/faq-detail/Proxies-for-Social-Media">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icSocialMedia} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b>Social Media</b><br/>
                                                            Dominate your industry space on socialmedia with smarter campaigns, anticipatethe next big trends.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link mb-4" href="/faq-detail/Sneaker">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icSneakerTicket} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b> Sneaker & Tickets</b><br/>
                                                            Improve your Sneakers retail game withoutlimitations or restrictions.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link mb-4" href="/faq-detail/DataScraping">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icDataScraping} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b> Data Scraping</b><br/>
                                                            Use the power of web data to improve the world.
                                                        </p>
                                                    </div>
                                                </a>
                                                <a className="user_axect_link " href="/faq-detail/ReviewMonitoring">
                                                    <div className='d-flex'>
                                                        <p className='mb-0 mr-2'>
                                                            <img src={icReviewMonitoring} width="48px" height="48px" style={{minWidth: "48px"}} />
                                                        </p>
                                                        <p>
                                                            <b> Review Monitoring</b><br/>
                                                            Review monitoring and analytics across major e-comm sites.
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={activeRoute('checksocks5') + (settingMenu?.check_socks5 == 1 ? ' new' : '')}>
                                <Link to="/check_sock">Check Socks5</Link>
                            </li>
                            <li className={activeRoute('login.html') + (user != null ? 'd-none' : '')}>
                                <Link to="/login.html">Login</Link>
                            </li>
                            <li className={activeRoute('register.html') + (user != null ? 'd-none' : '')}>
                                <Link to="/register.html">Register</Link>
                            </li>
                            <li className={activeRoute('my-account.html') + (user != null ? '' : 'd-none') + (settingMenu?.my_account == 1 ? ' new' : '')}>
                                <Link to="/my-account.html">Profile</Link>
                            </li>
                            <li className={activeRoute('ss5-wallet.html') + (settingMenu?.ss5_wallet == 1 ? ' new' : '')  + (user != null ? '' : ' d-none')}>
                                <Link to="/ss5-wallet.html">SS5 wallet</Link>
                            </li>
                            <li className={activeRoute('contacts') + (settingMenu?.contacts == 1 ? ' new' : '') + (user != null ? '' : ' d-none')}>
                                <Link to="/contacts.html">Contact</Link>
                            </li>
                            <li className={activeRoute('feedback') + (settingMenu?.contacts == 1 ? ' new' : '') + (user != null ? ' d-none' : '')}>
                                <Link to="/feedback.html">Contact</Link>
                            </li>
                            <li className={(user != null ? '' : 'd-none')}>
                                <a onClick={logout}>Logout</a>
                            </li>
                        </ul>
                    </div>
                    <div className={"notifications " + (user != null ? '' : 'd-none')}>
                        <ul className='pl-0 mb-0'>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav>
                                    {
                                        count > 0 ? (<Badge count={count} className="text-white"><i className="fas fa-bell font-18 text-white" aria-hidden="true"></i></Badge>) : <i className="fas fa-bell font-18 text-white" aria-hidden="true"></i>
                                    }
                                </DropdownToggle>
                                <DropdownMenu right className="mailbox pt-0">
                                    <span className="with-arrow"><span className="bg-white" style={{backgroundColor: "#fff"}}></span></span>
                                    <div className="message-center notifications">
                                        {   count > 0 && (<div className="view-all text-center">
                                                <a onClick={ChangeAll}>View All</a>
                                            </div>)
                                        }
                                        <ul className='tab-notification d-flex pl-0 bg-yellow'>
                                            <li className={type == 'all' ? ' active' : ''}>
                                                <a onClick={() => changeTab('all')}><i className="fa fa-globe" aria-hidden="true"></i> All</a>
                                            </li>
                                            <li className={type == 'alert' ? ' active' : ''}>
                                                <a onClick={() => changeTab('alert')}><i className="fa fa-comment" aria-hidden="true"></i> Website</a>
                                            </li>
                                            <li className={type == 'order' ? ' active' : ''}>
                                                <a onClick={() => changeTab('order')}><i className="fa fa-shopping-bag" aria-hidden="true"></i> Order</a>
                                            </li>
                                            <li className={type == 'reset' ? ' active' : ''}>
                                                <a onClick={() => changeTab('reset')}><i className="fa fa-reply-all" aria-hidden="true"></i> Reset</a>
                                            </li>
                                            <li className={type == 'convert' ? ' active' : ''}>
                                                <a onClick={() => changeTab('convert')}><i className="fa fa-compress" aria-hidden="true"></i> Convert</a>
                                            </li>
                                            <li className={type == 'contact' ? ' active' : ''}>
                                                <a onClick={() => changeTab('contact')}><i className="fa fa-question-circle" aria-hidden="true"></i> Contact</a>
                                            </li>
                                        </ul>
                                        <InfiniteScroll
                                            dataLength={data.length}
                                            next={getNotification}
                                            hasMore={hasMore}
                                            height={300}
                                            loader={<div className="text-center"><Spin size="small" /></div>}
                                            scrollableTarget="scrollableDiv"
                                        >
                                            {
                                                data && data.length > 0 && data.map((item, key) => 
                                                    <span onClick={() => showModal(item)} id={"message-item" + item.id} className={"message-item" + (((item.is_ready == 0 && item.is_all == 0) || (!hasUserView && item.is_all == 1 && item.is_check_datetime)) ? " not-ready" : "")} key={item.id}>
                                                        <span className="btn btn-circle btn-primary">
                                                            <i className="fa fa-bell"></i>
                                                        </span>
                                                        <div className="mail-contnet">
                                                            <h5 className="message-title">{item.title}</h5>
                                                            <span className="time">{item.date}</span>
                                                        </div>
                                                    </span>
                                                )
                                            }
                                        </InfiniteScroll>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ul>
                    </div>
                    <button className="navbar-toggler" type="button" onClick={onClick}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </nav>
                <div className="header-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="pull-left remove-margin"><b>Current Time: <span className="date-time-client text-black" id="timecontainer">21:30:21 (10/09/2021)</span></b></p>
                                <div className="pull-right">
                                    {/* <Link to="/faqs.html"><b><i className='fa fa-question-circle'></i> User Guide</b></Link>
                                    <a href="/faq-detail/terms-and-conditions-and-privacy-policy" className='pl-3'><b>Terms</b></a>
                                    <a href="/faq-detail/faq" className='pl-3'><b>FAQs</b></a> */}
                                </div>
                                {/* <div className="pull-right language d-none"><span>Languages: <span className="active">English or Vietnamese</span></span></div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="Notification" visible={isModalVisible} footer={false} onCancel={handleCancel}>
                    <h4>{current?.title}</h4>
                    <div className="mt-2" dangerouslySetInnerHTML={{__html: (current?.description) }} />
                    {
                        current?.type == 'new_order' ? (
                            <p><b>You can see all order at: <a href="/orders.html">Orders History</a></b></p>
                        ) : ""
                    }
                    {
                        current?.type == 'reset_plan' || current?.type == 'reset_plan_free' ? (
                            <p><b>You can see all reset limit at: <a href="/reset.html">Reset History</a></b></p>
                        ) : ""
                    }
                    {
                        current?.type == 'convert' ? (
                            <p><b>You can see all Convert at: <a href="/convert.html">Convert History</a></b></p>
                        ) : ""
                    }
                    <div className="mt-2 text-right"><b>{current?.date}</b></div>
                </Modal>
            </header>
        </>
    );
}
export default Header;
