import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Spin } from 'antd';
import { payment } from '../../endpoint';
import { getParamsByUrlPaging, constants } from '../../_until';

import qrTitle from '../../assets/images/qr-title.png';

const PaymentCryptomusDetail = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [dataPayment, setDataPayment] = useState(null);
    const [plan, setPlan] = useState(null);
    const [type, setType] = useState(null);
    const [fee, setFee] = useState(5);
    const [discount, setDiscount] = useState(0);
    const [showIconLoading, setShowIconLoading] = useState(false);
    const [textTime, setTextTime] = useState('');
    const { code } = useParams();
    var startTimerInterval = null, checkPaymentInterval = null;

    /**
     * Get Coin Name
     *
     * @param type
     */
    const getCoinName = (type) => {
        if (type == constants.Bitcoin.toLowerCase()) {
            return 'Bitcoin (BTC)';
        } else if (type == constants.BitcoinCash.toLowerCase()) {
            return 'Bitcoin Cash (BCH)';
        } else if (type == constants.TRC20.toLowerCase()) {
            return 'USDT (TRC20)';
        } else if (type == constants.ERC20.toLowerCase()) {
            return 'USDT (ERC20)';
        } else if (type == "bsc") {
            return 'USDT BSC (BNB Smart Chain(BEP20))';
        } else if (type == constants.Litecoin.toLowerCase()) {
            return 'Litecoin (LTC)';
        } else if (type == constants.DASH.toLowerCase()) {
            return 'DASH';
        } else if (type == constants.ETH.toLowerCase()) {
            return 'Ethereum (ETH)';
        } else if (type == constants.TRON.toLowerCase()) {
            return 'Tron (TRX)';
        }
        return '';
    }

    /**
     * Handle format time
     *
     * @param seconds
     */
    const formatTime = (seconds) => {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);
        var remainingSeconds = seconds % 60;

        return hours.toString().padStart(2, '0') + ' Hours ' +
                minutes.toString().padStart(2, '0') + ' Minutes ' +
                remainingSeconds.toString().padStart(2, '0') + ' Seconds';
    }

    /**
     * Handle update timer
     *
     * @param expiredAtUnix
     */
    const updateTimer = (expiredAtUnix = 0) => {
        var currentTimeUnix = Math.floor(Date.now() / 1000);
        var remainingTime = expiredAtUnix - currentTimeUnix;
        if (remainingTime <= 0) {
            clearInterval(startTimerInterval);
            setTextTime('Expired');
            setShowIconLoading(false);
        } else {
            setTextTime(formatTime(remainingTime));
            setShowIconLoading(true);
        }
    }

    /**
     * Handle check status payment
     */
    const checkStatusPayment = () => {
        payment.check_status_history({code: code}).then(res => {
            if (res.status == 'success') {
                if (res.pm_status == 2) {
                    clearInterval(checkPaymentInterval);
                    window.location.href = "/";
                }
                if (res?.is_wrong == 1) {
                    clearInterval(checkPaymentInterval);
                    window.location.href = "/success.html?type=wrong_amount";
                }
                if (res.check) {
                    clearInterval(checkPaymentInterval);
                    window.location.href = "/success.html";
                }
                if (!dataPayment) {
                    setDataPayment(res.dataPayment);
                    setPlan(res.plan);
                    setType(res.type);
                    updateTimer(res.dataPayment.expired_at);
                    setDiscount(res.discount);
                    startTimerInterval = setInterval(() => {
                        updateTimer(res.dataPayment.expired_at)
                    }, 1000);
                }
                setLoading(false);
            }
        }).then(complete => {
        }).then(err => {
        });
    }

    /**
     * Handle calculator fee
     */
    const calculatorFee = () => {
        let planPrice = parseFloat(plan?.plan_price);
        if (discount > 0) {
            return (planPrice - calculatorDiscount()) * fee / 100;
        }
        return planPrice * fee / 100;
    }

    /**
     * Handle calculator discount
     */
    const calculatorDiscount = () => {
        return (parseFloat(plan?.plan_price) * discount / 100);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Make Payment - " + window?.title;
        if (code) {
            checkStatusPayment();
            checkPaymentInterval = setInterval(checkStatusPayment, 1000 * 60);
        }
        return () => {
            if (startTimerInterval) {
                clearInterval(startTimerInterval);
            }
            if (checkPaymentInterval) {
                clearInterval(checkPaymentInterval);
            }
        }
    }, []);

    if (!code) {
        history.push({pathname: '/home'});
    }

    return (
        <div className="container">
            {
                loading ? 
                (<div className="text-center mt-5"><Spin /></div>) :
                (
                    <div className='payment-form-crypto'>
                        <div style={{paddingTop: '15px', paddingBottom: "30px"}}>
                            <div className="text-center" style={{borderBottom: '1px solid #ddd', paddingBottom: '15px', marginBottom: '15px'}}>
                                <img width="25px" src={qrTitle} />
                                <strong className="pl-4" style={{fontSize: '16px'}}>You buy: {plan?.plan_name} {(type == 'plan' && plan?.plan_expired > 0) ? "| Expire: " + plan?.plan_expired + " days " : "" }</strong>
                            </div>
                            <div className="table-responsive">
                                <p className='text-center' style={{fontSize: '16px'}}>Please check careful and send the correct {getCoinName(dataPayment?.network)} address shown. You should use QR CODE to avoid computer virus infection and then send it to hacker. Upon first confirmation of the transaction, your account will be activated</p>
                                <table className="table table-striped table-not-mobile" style={{fontSize: '16px'}}>
                                    <tbody>
                                        <tr>
                                            <td className="text-right" width="40%">
                                                <strong>Price:</strong>
                                            </td>
                                            <td className="text-left">{parseFloat(plan?.plan_price)}$</td>
                                        </tr>
                                        {
                                            discount > 0 && (
                                                <tr>
                                                    <td className="text-right" width="40%">
                                                        <strong>Discount ({discount}%):</strong>
                                                    </td>
                                                    <td className="text-left">{calculatorDiscount()}$</td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td className="text-right" width="40%">
                                                <strong>Fee ({fee}%):</strong>
                                            </td>
                                            <td className="text-left">{calculatorFee()}$</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" width="40%">
                                                <strong>Network:</strong>
                                            </td>
                                            <td className="text-left">{getCoinName(dataPayment?.network)}</td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#FBFBFB' }}>
                                            <td className="text-right" width="40%">
                                                <strong>{dataPayment?.payer_currency} Address:</strong>
                                            </td>
                                            <td className="text-left">
                                                <b className='text-danger'>{dataPayment?.address}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" width="40%">
                                                <strong>Amount:</strong>
                                            </td>
                                            <td className="text-left">
                                                <b className='text-danger'>{dataPayment?.payer_amount} {dataPayment?.payer_currency}</b>
                                            </td>
                                        </tr>
                                        {
                                            showIconLoading && (
                                                <tr style={{ backgroundColor: '#FBFBFB' }}>
                                                    <td className="text-right" width="40%">
                                                        <strong>Status:</strong>
                                                    </td>
                                                    <td className="text-left payment-instruction">
                                                        {
                                                            dataPayment?.status == 1 ?
                                                            (<span className="badge badge-success" style={{lineHeight: '1.2'}}>Complete</span>) :
                                                            (<span className="badge badge-info" style={{lineHeight: '1.2'}}>Pending</span>)
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="text-center mb-4">
                                <p style={{fontSize: '16px'}} className='mb-0'><b>{getCoinName(dataPayment?.network)} Address QR CODE:</b></p>
                                <img src={dataPayment?.address_qr_code} className="img-fluid" width="220px" />
                            </div>
                            <div className="text-center mt-3">
                                <h5>{getCoinName(dataPayment?.network)} Address wallet will expire after</h5>
                                <p className="time-count-down" id="timerDisplay">{textTime}</p>
                                <p className="text-danger" style={{fontSize: '16px'}}>Please don't send {getCoinName(dataPayment?.network)} this address after address is expired, we are not received money after address is expired.</p>
                            </div>
                            {
                                showIconLoading && (
                                    <div className="text-center mt-3">
                                        <h4>Waiting for your payment</h4>
                                        <div><Spin /></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}
export default PaymentCryptomusDetail;
