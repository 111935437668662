import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import { getParamsByUrlPaging } from '../../_until';

const Success = () => {
    const history = useHistory();
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Success - " + window?.title;

        if (paramQuery?.type == 'trial_proxy') {
            setTimeout(function() {
                location.href = '/list-proxy.html';
            }, 3000);
        } else if (paramQuery?.type == 'trial_vps') {
            setTimeout(function() {
                location.href = '/list-vps.html';
            }, 3000);
        } else if (paramQuery?.type == 's5') {
            setTimeout(function() {
                location.href = '/cdkey-detail.html';
            }, 3000);
        }
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="mt-5 pt-5 pb-5 success-box" style={{maxWidth: "640px", background: "#fff", margin: "0 auto", width: "100%"}}>
                        <CheckCircleOutlined style={{color: "#99d834"}} />
                        <p style={{fontSize: "28px", fontWeight: "bold", marginBottom: "0px"}}>Success</p>
                        {
                            paramQuery?.type == 'wrong_amount' ? (
                                <p style={{fontSize: "16px"}}>
                                    You underpaid money so we have converted your deposit to SS5 Points<br/>
                                    Please use SS5 Points to purchase our products
                                </p>
                            ) : (
                                <p style={{fontSize: "16px"}}>Your request was processed successfully.</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Success;
