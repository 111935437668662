import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined, CommentOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, Input, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormDigit, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { package_setting, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await package_setting.addItem({ ...fields, 'status': 1});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param id
 */
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await package_setting.updateItem({ ...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Add Note
 *
 * @param fields
 * @param id
 */
const handleAddNote = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await package_setting.addNote(id, { ...fields});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: res.message,
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await package_setting.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param status
 * @param record
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await package_setting.changeStatus(record.id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const PackageSettingComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [createModalVisibleNote, handleModalVisibleNote] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState({});
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [dataService, setDataService] = useState({});
    const [settingId, setId] = useState(0);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history = useHistory();
    const [form] = Form.useForm();
    const [formNote] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);

        const data = await package_setting.getList(parameters);
        setDataService(data.dataSerive);
        return data;
    }

    /**
     * Load package list
     */
    const packagesList = async () => {
        const data = await package_setting.getPackageList();
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "Name",
            dataIndex: 'name',
            sorter: false,
            valueType: 'text',
            hideInSearch: true,
        },
        {
            title: "Supplier",
            dataIndex: 'type',
            initialValue: paramQuery?.type,
            valueEnum: {
                "922": {
                    text:'922 S5Proxy',
                },
                "abc": {
                    text: "ABC S5Proxy"
                },
                "pia": {
                    text: "PIA S5Proxy"
                },
                "922Traffic": {
                    text: "922 Traffic S5Proxy"
                },
                "luna": {
                    text: "Luna S5Proxy"
                }
            },
        },
        {
            title: "Number",
            dataIndex: 'number',
            sorter: false,
            valueType: 'text',
            hideInSearch: true,
        },
        {
            title: "Type Proxies",
            dataIndex: 'type_proxies',
            initialValue: paramQuery?.type_proxies,
            valueEnum: {
                "isp": {
                    text:'ISP Proxies (IP)',
                },
                "traffic": {
                    text: "Traffic Proxies (GB)"
                }
            },
        },
        {
            title: "Status",
            dataIndex: 'status',
            valueEnum: {
                0: {
                    text:'Not Using',
                    status: 'Success',
                },
                1: {
                    text: "Using",
                    status: 'red',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Using" unCheckedChildren="Not Using" onChange={(value) => handledChangeStatus(value, record)} key={'status' + record.id} defaultChecked={record?.status  == 1 ? true : false} />
            ]
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true,
        },
        {
            title:'CDKey',
            dataIndex: 'cdkey',
            hideInSearch: true,
        },
        {
            title:'User Name',
            dataIndex: 'user_name',
            hideInSearch: true,
        },
        {
            title:'Date Buy Cdkey',
            dataIndex: 'date_buy',
            hideInSearch: true,
        },
        {
            title:'Note',
            dataIndex: 'note',
            hideInSearch: true,
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key="note" onClick={() => { setId(record.id); handleModalVisibleNote(true); }} className="btn btn-success text-white">
                    <CommentOutlined /> Note
                </a>,
                <a className='d-none' key={"edit" + record.id}
                    onClick={() => {
                        form.resetFields();
                        setCurrentRow(record);
                        form.setFieldsValue(record);
                        handleModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={"delete" + record.id}
                    title="Are you sure to delete this setting?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a className='text-danger'><DeleteOutlined /> Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="List CDkey Manager"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="add_primary"
                        onClick={() => {
                            form.resetFields();
                            setCurrentRow(null);
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>,
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
            />
            <div className='ant-pro-card'>
                <div className='ant-pro-card-body'>
                    <p>
                        <b>Total:</b><br/>
                        <b>922 S5Proxy</b>: {dataService?.['922'] ?? 0} CDkey<br/>
                        <b>PIA S5Proxy</b>: {dataService?.pia ?? 0} CDkey<br/>
                        <b>ABC S5Proxy</b>: {dataService?.abc ?? 0} CDkey<br/>
                        <b>922 Traffic S5Proxy</b>: {dataService?.['922traffic'] ?? 0} CDkey<br/>
                    </p>
                </div>
            </div>

            {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete-confirm"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new and Edit*/}
            <ModalForm
                id="form-add-package"
                title="Package Setting Form"
                width="640px"
                form={form}
                submitter={false}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                }}
            >
                <ProFormTextArea
                    rules={[
                        {
                            required: true,
                            message: "List is required"
                        },
                    ]}
                    row="10"
                    width="full"
                    name="name"
                    label="List:"
                />

                <ProFormSelect
                    name="type_proxies"
                    label="Type Proxies"
                    rules={[
                        {
                            required: true,
                            message: "Type Proxies is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'ISP Proxies (IP)', value: 'isp'},
                        { label: 'Traffic Proxies (GB)', value: 'traffic' }
                    ]}
                    value={currentRow?.type}
                    placeholder="Type Proxies"
                />

                <ProFormDigit
                    rules={[
                        {
                            required: true,
                            message: "Number is required"
                        },
                    ]}
                    label="Number"
                    name="number"
                    width="full"
                    value={currentRow?.number}
                    min={1}
                />

                <ProFormSelect
                    name="type"
                    label="Supplier"
                    rules={[
                        {
                            required: true,
                            message: "Supplier is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'S5 922', value: '922'},
                        { label: 'S5 ABC', value: 'abc' },
                        { label: 'S5 PIA', value: 'pia' }
                    ]}
                    value={currentRow?.type}
                    placeholder="Supplier"
                />

                <div className='text-right'>
                    <Button
                        type="primary"
                        key="submitForm"
                        onClick={async () => {
                            const values = form.getFieldsValue();
                            form.submit();
                            if (values.name && values.type != null) {
                                if (currentRow?.id) {
                                    const success = await handleUpdate({...values}, currentRow?.id);
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                        if (actionRef.current) {
                                            actionRef.current.reload();
                                        }
                                    }
                                } else {
                                    const success = await handleAdd({...values});
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                        if (actionRef.current) {
                                            actionRef.current.reload();
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </ModalForm>

            {/*Form Add Note*/}
            <ModalForm
                id="form-add-note"
                title="Add Note"
                width="500px"
                form={formNote}
                visible={createModalVisibleNote}
                onVisibleChange={handleModalVisibleNote}
                onFinish={async (value) => {
                    const success = await handleAddNote(value, settingId);
                    if (success) {
                        formNote.resetFields();
                        handleModalVisibleNote(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >    
                <Form.Item name="note" width="lg" label="Note" rules={[{ required: true }]}>
                    <Input.TextArea rows="6" />
                </Form.Item>
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default PackageSettingComponent;
