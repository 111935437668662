import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, Form, notification, Button, Checkbox, Radio, message } from 'antd';
import { account } from '../../../endpoint';
import CountdownTimer from './CountdownTimer';
import mostPopular from '../../../assets/theme/images/most_popular.png';

const VpsItem = ({item, profile, isShowTrialVps, dataOs}) => {
    const history = useHistory();
    const [isModalVisibleTrialVps, setIsModalVisibleTrialVps] = useState(false);
    const [checkIhaveRead, setCheckIhaveRead] = useState(false);
    const [packageIdTrialVps, setPackageIdTrialVps] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));

    /**
     * Handle calculater discount
     */
    const handledCalculaterDiscount = (item) => {
        if (item?.discount) {
            return item?.plan_price - parseInt((item?.plan_price * item?.discount.discount)/100);
        }
        return item?.plan_price;
    }

    /**
     * Handle register trial vps
     *
     * @param values
     */
    const handleTrialVps = (values) => {
        if (!checkIhaveRead) {
            notification.open({
                message: 'Fail!',
                description: 'I have read and agree is required',
                type: 'error'
            });
            return;
        }
        const hide = message.loading('Processing...');
        setIsModalVisibleTrialVps(false);
        account.check_trial_vps({...values, 'id': packageIdTrialVps}).then(res => {
            if (res.status == 'success') {
                location.href = window.base_url + 'vps-google-redirect/' + res.trial_id;
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Trial registration failed.',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(complete => {
            hide();
        }).then(err => {
            hide();
        });
    }

    /**
     * Show button trial vps
     *
     * @param packageId
     */
    function getButtonTrialVps(packageId) {
        return (
            isShowTrialVps && (
                <a className="btn-continue-google d-block mr-0 ml-0 mb-2" style={{minWidth: "150px"}} onClick={() => {
                    if (user != null) {
                        setPackageIdTrialVps(packageId);
                        setIsModalVisibleTrialVps(true);
                    } else {
                        history.push({pathname: '/login.html'});
                        return;
                    }
                }}>Free Trial</a>
            )
        )
    }

    /**
     * Handle check block buy vps
     *
     * @param packageId
     */
    function checkBlockBuyVps(packageId) {
        if (profile?.is_buy_vps == 0) {
            notification.open({
                message: 'Fail!',
                description: "Your account is temporarily locked for 3 days due to violating the rules.",
                type: 'error'
            });
            return ;
        } else if (profile?.is_buy_vps == 2) {
            notification.open({
                message: 'Fail!',
                description: "Your account has been permanently locked due to repeated violations of the rules.",
                type: 'error'
            });
            return ;
        }
        location.href = `/buy-vps/` + packageId;
        return;
    }

    /**
     * Check os by ram and cpu
     *
     * @param ram
     * @param cpu
     */
    const checkOsByRamCpu = (ram, cpu, os) => {
        if (typeof ram == 'undefined' || typeof cpu == 'undefined') {
            return false;
        }
        ram = parseInt(ram.replace("GB", "").trim());
        cpu = parseInt(cpu.replace("Core", "").trim());
        if (ram < 2 || cpu < 2) {
            if (os == 'windows-server-2022-standard') {
                return false;
            }
            if (os == 'windows-10-enterprise') {
                return false;
            }
        }
        if (ram < 4) {
            if (os == 'windows-10-pro') {
                return false;
            }
            if (os == 'win-11-pro') {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
    }, [item, profile, isShowTrialVps, dataOs]);

    return (
        <>
            <div className={"package-s5 package-proxy" + (item?.is_feature == 1 ? ' package_popular' : '')}>
                <div className="package-side package-side-left">
                    {
                        item?.is_feature == 1 && (
                            <div className="popular-image">
                                <img src={mostPopular} width="112px" height="43px" />
                            </div>
                        )
                    }
                    <div className="package-header">
                        <h3 className="package-title">
                            {
                                item?.icon ? (<img src={window.base_url + item.icon} style={{width: "30px", height: "auto", marginRight: "10px"}} />) : ''
                            }
                            <b>{item.plan_name}</b>
                        </h3>
                        <div className="package-price">
                            <div className="price">
                                <div className="price-amount">
                                    <span className='current-price'>${parseFloat(handledCalculaterDiscount(item)/10).toFixed(2)}</span>
                                    {
                                        item?.discount ? (
                                            <span className='discount-price'>${parseFloat(item?.plan_price/10).toFixed(2)}</span>
                                        ) : ""
                                    }
                                </div>
                                <div className="price-cycle">30 days</div>
                            </div>
                        </div>
                    </div>
                    <div className="package-body">
                        <div className="package-content">
                            <ul className="package-features">
                                <li><b>CPU:</b> {item?.cpu}</li>
                                <li><b>RAM:</b> {item?.ram}</li>
                                <li><b>Disk:</b> {item?.disk}</li>
                                <li><b>IPv4:</b> {item?.ipv4}</li>
                                <li><b>Bandwidth:</b> {item?.bandwidth}</li>
                                <li><b>Type:</b> Datacenter</li>
                                <li><b>Ethernet Port:</b> {item?.ethernet_port}</li>
                                <li><b>IPV6:</b> {item?.ipv6}</li>
                                <li><b>GPU:</b> No GPU</li>
                                <li><b>Supplier:</b> {item?.supplier}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="package-footer package-side package-side-right">
                    <a onClick={() => {checkBlockBuyVps(item.id)}} className='btn btn-primary mb-3 d-block'><i className="fa fa-shopping-cart"></i> Buy Now</a>
                    { item.is_trial == 1 ? getButtonTrialVps(item.id) : '' }
                    {
                        item?.discount ? (
                            <p className="actlabel_coner d-block text-danger mb-0">SALE OFF {item?.discount.discount}%</p>
                        ) : ""
                    }
                    {
                        item?.discount && item?.discount?.end_date ? (
                            <div className='text-center'>
                                <span>Offer Ends Soon</span>: <b className="text-uppercase"><CountdownTimer targetDate={item?.discount.end_date} show="Expired" /></b>
                            </div>
                        ) : ""
                    }
                </div>
            </div>
            <Modal visible={isModalVisibleTrialVps} footer={null} onCancel={() => {setIsModalVisibleTrialVps(false)}} width={768}>
                <div className="row">
                    <div className="col-md-12">
                        <p className='mb-1 text-center'>We offer a package free trial. (<span className='text-danger'>Time trial: 60 minutes</span>)</p>
                        <p className='mb-1 text-center'>You plz connect account google.</p>
                        <p className='mb-1 text-center'>My site will activate your account package trial</p>
                        <p className='mb-1 text-center text-danger'>We only support personal emails. @gmail email address</p>
                        <p className='text-center'>Thank you very much</p>
                        <Form
                            name="basic"
                            className="form"
                            onFinish={(values) => { handleTrialVps(values) }}
                        >
                            <div className="row-eq-height group-choo-os">
                                <Form.Item
                                    label="Operating system:"
                                    labelCol={{span: 24}}
                                    name="os"
                                    rules={[{ required: true, message: 'Please choose Operating system!'}]}
                                >
                                    <Radio.Group>
                                        {
                                            dataOs.length > 0 && dataOs.map((itemOs, key) => 
                                                checkOsByRamCpu(item?.ram, item?.cpu, itemOs.key) && <Radio key={'os' + itemOs.id} value={itemOs.key}>
                                                    <div className='os-item'>
                                                        <img src={window.base_url + itemOs?.image} width="100px" />
                                                        <p>{itemOs?.title}</p>
                                                    </div>
                                                </Radio>
                                            )
                                        }
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <Form.Item
                                name="i_have_read"
                                valuePropName="checked"
                                rules={[
                                    { required: true, message: 'I have read and agree is required' }
                                ]}
                            >
                                <Checkbox checked={checkIhaveRead} onChange={(e) => {setCheckIhaveRead(e.target.checked);}}>I have read and agree to the <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target='_blank'>Terms of Service</a></Checkbox>
                            </Form.Item>
                            <div className='row'>
                                <div className='col-12 col-sm-6'>
                                    <Button type="primary" htmlType="submit" className="d-block medium">Continue</Button>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <Button type="danger" htmlType="button" onClick={() => {setIsModalVisibleTrialVps(false)}} className="d-block medium">Cancel</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default VpsItem;
